<template>
  <div class="box">
    <div class="hd">设备运行统计</div>
    <div class="comments" style="margin-left: 20px">设备总数<span>1234</span>台</div>
    <div class="bd">
      <div id="device" style="width:100%; height:100%"/>
    </div>
    <dv-decoration-2 style="height:10px;" />
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'RightChart1',
  data () {
    return {
    }
  },
  methods: {
    load() {
      this.drawAccess()
    },
    drawAccess(){
      var myChart = echarts.init(document.getElementById("device"), 'dark');
      var option
     
      option = {
          backgroundColor:'',
          grid:{
            x:5,
            y:5,
            x2:5,
            y2:5,
            borderWidth:1
          },
          tooltip: {
              trigger: 'item'
          },
          legend: {
              orient: 'horizontal',
              left: 'left',
          },
          series: [
              {
                  name: '设备运行状态',
                  type: 'pie',
                  radius: '50%',
                  data: [
                      {value: 1048, name: '在线'},
                      {value: 235, name: '离线'},
                      {value: 100, name: '故障'},
                  ],
                  emphasis: {
                      itemStyle: {
                          shadowBlur: 10,
                          shadowOffsetX: 0,
                          shadowColor: 'rgba(0, 0, 0, 0.5)'
                      }
                  }
              }
          ]
      };
      // myChart.setOption(option);
      option && myChart.setOption(option);
    }
  },
  mounted () {
    this.load()
  }
}
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 0;
  flex-direction: column;

  .hd {
    text-align: center;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .comments {
    text-align: left;
    height: 30px;
    font-size: 20px;
    font-weight: bold;
    span {
      color:#096dd9;
      font-size: 35px;
      font-weight: bold;
    }
    margin-bottom: 10px;
  }

  .bd {
    flex:1;
    height: 50px;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-indent: 20px;

    span {
      color: #096dd9;
      font-weight: bold;
      font-size: 35px;
      margin-left: 20px;
    }
  }

  .chart {
    flex: 1;
  }
}

ul {
  padding: 0 1em;
  list-style: none;
  li {
    font-size: 1em;
    padding: 3px 0;
    font-weight: bold;
    a {
      color: #fff;
    }
    .label {
      display: inline-block;
      margin-right: 10px;
      color:#25c6fc;
    }
  }
}
</style>
