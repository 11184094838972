import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/components/Home'
import Login from '@/components/Login'
// import Dashboard from '@/components/Dashboard'
import Dashboard from '@/components/Dashboard2/index'
// import CampuseDashboard from '@/components/CampuseDashboard'
// import FireDashboard from '@/components/FireDashboard'
import Developing from '@/components/Developing'
import PowerPerformanceChart from '@/components/PowerPerformanceChart'
import PowerPerformanceData from '@/components/PowerPerformanceData'
// import MonitorDustPerformanceData from '@/components/MonitorDustPerformanceData'
// import MonitorWaterLevelPerformanceData from '@/components/MonitorWaterLevelPerformanceData'
// import MonitorTemperaturePerformanceData from '@/components/MonitorTemperaturePerformanceData'
// import MonitorPressurePerformanceData from '@/components/MonitorPressurePerformanceData'
// import FireHydrantPerformanceData from '@/components/FireHydrantPerformanceData'
// import WaterPressurePerformanceData from '@/components/WaterPressurePerformanceData'
// import WaterLevelPerformanceData from '@/components/WaterLevelPerformanceData'
// import DeviceAlarmRealTime from '@/components/DeviceAlarmRealTime'
// import DeviceAlarmHistory from '@/components/DeviceAlarmHistory'
// import DeviceList from '@/components/DeviceList'
import PowerSwitch from '@/components/PowerSwitch'
import UserList from '@/components/UserList'
// import PowerAlarmThreshold from '@/components/PowerAlarmThreshold'
import MyInfo from '@/components/MyInfo'
import MerchantList from '@/components/MerchantList'
import MerchantAdd from '@/components/MerchantAdd'
import PowerFixTimeChart from '@/components/PowerFixTimeChart'
import PowerTemperatureRiseChart from '@/components/PowerTemperatureRiseChart'
import FireDeviceAdd from '@/components/FireDeviceAdd'
// import FireDevice from '@/components/FireDevice'
import VideoMonitor from '@/components/VideoMonitor'
// import VideoDashboard from '@/components/VideoDashboard'
// import FirePerformanceChart from '@/components/FirePerformanceChart'
// import FirePerformanceData from '@/components/FirePerformanceData'
// import FireAlarmDashboard from '@/components/FireAlarmDashboard'
import InspectionList from '@/components/InspectionList'
// import RescuePlanList from '@/components/RescuePlanList'
// import RescueResourceList from '@/components/RescueResourceList'
import InspectionPointQrCode from '@/components/InspectionPointQrCode'
// import DutyInspectRecord from '@/components/DutyInspectRecord'
// import DutyInspectPoint from '@/components/DutyInspectPoint'
// import FpRoadInspectRecord from '@/components/FpRoadInspectRecord'
// import FpRoadInspectPoint from '@/components/FpRoadInspectPoint'
// import ReportWeek from '@/components/ReportWeek'
// import ReportMonth from '@/components/ReportMonth'
// import ReportMerchantList from '@/components/ReportMerchantList'
// import WorkOrderDashboard from '@/components/WorkOrderDashboard'
// import SmokeDashboard from '@/components/SmokeDashboard'
// import SmokeDashboard2 from '@/components/SmokeDashboard2'
// import SmokePerformanceData from '@/components/SmokePerformanceData'
// import PersonDetectPerformanceData from '@/components/PersonDetectPerformanceData'
// import DocumentArchiveList from '@/components/DocumentArchiveList'
// import SmokeInfo from '@/components/SmokeInfo'
// import SmokeAlarmList from '@/components/SmokeAlarmList'
// import SmokeAlarm from '@/components/SmokeAlarm'
// import SmokeAlarmCount from '@/components/SmokeAlarmCount'
import OperationLog from '@/components/OperationLog'

Vue.use(Router)

let router = new Router({
  // mode: 'history',
  routes: [{
    path: '/',
    redirect: '/login'
  }, {
    path: '/login',
    name: 'Login',
    component: Login
  }, {
    path: '/dashboard',
    name: 'Home',
    component: Home,
    leaf: true, // 只有一个节点
    menuShow: true,
    iconCls: 'fa fa-home fa-fw', // 图标样式class
    children: [{
      path: '/dashboard',
      component: Dashboard,
      name: '首页',
      menuShow: true
    }]
  // }, {
  //   path: '/CampuseDashboard',
  //   name: 'CampuseDashboard',
  //   component: Home,
  //   leaf: true, // 只有一个节点
  //   menuShow: true,
  //   iconCls: 'fa fa-university fa-fw', // 图标样式class
  //   children: [{
  //     path: '/CampuseDashboard',
  //     component: CampuseDashboard,
  //     name: '校园首页',
  //     menuShow: true
  //   }]
  }, 
  // {
  //   path: '/rescue',
  //   component: Home,
  //   name: '应急救援平台',
  //   menuShow: true,
  //   mod: 'rescue',
  //   iconCls: 'fa fa-flash fa-fw',
  //   children: [{
  //     path: '/rescue/plan',
  //     component: RescuePlanList,
  //     name: '应急救援预案',
  //     menuShow: true,
  //     iconCls: 'fa fa-file-text fa-fw'
  //   }, {
  //     path: '/rescue/resource',
  //     component: RescueResourceList,
  //     name: '资源和危化品',
  //     menuShow: true,
  //     iconCls: 'fa fa-cubes fa-fw'
  //   }, {
  //     path: '/document/archive',
  //     component: DocumentArchiveList,
  //     name: '电子档案室',
  //     menuShow: true,
  //     iconCls: 'fa fa-archive fa-fw'
  //   }]
  // }, 
  // {
  //   path: '/report/week',
  //   component: Home,
  //   name: '消防指数报告',
  //   menuShow: true,
  //   iconCls: 'fa fa-bullseye fa-fw',
  //   children: [{
  //     path: '/report/merchantList',
  //     component: ReportMerchantList,
  //     name: '报告列表',
  //     menuShow: true,
  //     iconCls: 'fa fa-list-alt fa-fw'
  //   }, {
  //     path: '/report/week',
  //     component: ReportWeek,
  //     name: '周报',
  //     menuShow: true,
  //     iconCls: 'fa fa-clock-o fa-fw'
  //   }, {
  //     path: '/report/month',
  //     component: ReportMonth,
  //     name: '月报',
  //     menuShow: true,
  //     iconCls: 'fa fa-clock-o fa-fw'
  //   }]
  // }, 
  // {
  //   path: '/workOrder',
  //   name: 'Home',
  //   component: Home,
  //   leaf: true,
  //   menuShow: true,
  //   iconCls: 'fa fa-wrench fa-fw',
  //   children: [{
  //     path: '/workOrder/dashboard',
  //     component: WorkOrderDashboard,
  //     name: '工单管理',
  //     menuShow: true
  //   }]
  // }, 
  // {
  //   path: '/fire',
  //   name: '消防监控平台',
  //   component: Home,
  //   menuShow: true,
  //   iconCls: 'fa fa-fire fa-fw', // 图标样式class
  //   children: [{
  //     path: '/fire/dashboard',
  //     component: FireDashboard,
  //     name: '消防监控面板',
  //     menuShow: true,
  //     iconCls: 'fa fa-shield fa-fw'
  //   }, {
  //     path: '/fire/report/performance/chart',
  //     component: FirePerformanceChart,
  //     name: '图表分析',
  //     menuShow: true,
  //     iconCls: 'fa fa-line-chart fa-fw'
  //   }, {
  //     path: '/fire/report/performance/data',
  //     component: FirePerformanceData,
  //     name: '数据分析',
  //     menuShow: true,
  //     iconCls: 'fa fa-table fa-fw'
  //   }, {
  //     path: '/fire/device',
  //     component: FireDevice,
  //     name: '消防设备',
  //     menuShow: false
  //   }]
  // }, 
  {
    path: '/video/dashboard',
    name: 'Home',
    component: Home,
    leaf: true,
    menuShow: true,
    mod: 'video',
    iconCls: 'fa fa-video-camera fa-fw',
    children: [
    // {
    //   path: '/video/dashboard',
    //   component: VideoDashboard,
    //   name: '居家安全监控',
    //   menuShow: true
    // }
  ]
  }, {
    path: '/report',
    component: Home,
    name: '智慧用电监控',
    menuShow: true,
    iconCls: 'fa fa-area-chart fa-fw',
    children: [{
      path: '/report/power/performance/chart',
      component: PowerPerformanceChart,
      name: '性能图表',
      menuShow: true,
      iconCls: 'fa fa-line-chart fa-fw'
    }, {
      path: '/report/power/performance/data',
      component: PowerPerformanceData,
      name: '电表性能数据',
      menuShow: true,
      iconCls: 'fa fa-table fa-fw'
    }]
  }, 
  // {
  //   path: '/water',
  //   component: Home,
  //   name: '消防用水监控',
  //   menuShow: true,
  //   iconCls: 'fa fa-tint fa-fw',
  //   children: [{
  //     path: '/report/fireHydrant/performance/data',
  //     component: FireHydrantPerformanceData,
  //     name: '消防栓数据',
  //     menuShow: true,
  //     iconCls: 'fa fa-tree fa-fw'
  //   }, {
  //     path: '/report/waterPressure/performance/data',
  //     component: WaterPressurePerformanceData,
  //     name: '智能水压数据',
  //     menuShow: true,
  //     iconCls: 'fa fa-tachometer fa-fw'
  //   }, {
  //     path: '/report/waterLevel/performance/data',
  //     component: WaterLevelPerformanceData,
  //     name: '智能水位数据',
  //     menuShow: true,
  //     iconCls: 'fa fa-sort fa-fw'
  //   }]
  // }, 
  {
    path: '/device/power/switch',
    component: Home,
    name: '远程开关监控',
    leaf: true,
    menuShow: true,
    iconCls: 'fa fa-toggle-on fa-fw',
    children: [{
      path: '/device/power/switch',
      component: PowerSwitch,
      name: '远程开关监控',
      menuShow: true,
      // role: 1001,
      iconCls: 'fa fa-toggle-on fa-fw'
    }]
  }, 
  // {
  //   path: '/smoke',
  //   component: Home,
  //   name: '智能烟感监控',
  //   menuShow: true,
  //   iconCls: 'fa fa-circle-o fa-fw',
  //   children: [{
  //   //   path: '/smoke/dashboard',
  //   //   component: SmokeDashboard,
  //   //   name: '智能烟感监控',
  //   //   menuShow: true,
  //   //   iconCls: 'fa fa-circle-o fa-fw'
  //   // }, {
  //     path: '/smoke/dashboard2',
  //     component: SmokeDashboard2,
  //     name: '智能烟感监控',
  //     menuShow: true,
  //     iconCls: 'fa fa-circle-o fa-fw'
  //   }, {
  //     path: '/report/smoke/performance/data',
  //     component: SmokePerformanceData,
  //     name: '智能烟感数据',
  //     menuShow: false,
  //     iconCls: 'fa fa-table fa-fw'
  //   }, {
  //     path: '/report/personDetect/performance/data',
  //     component: PersonDetectPerformanceData,
  //     name: '超声识别数据',
  //     menuShow: false,
  //     iconCls: 'fa fa-user-o fa-fw'
  //   }, {
  //     path: '/smoke/info',
  //     component: SmokeInfo,
  //     name: '设备信息',
  //     menuShow: false,
  //     iconCls: 'fa fa-cube fa-fw'
  //   }, {
  //     path: '/smoke/alarm',
  //     component: SmokeAlarm,
  //     name: '报警数据',
  //     menuShow: false,
  //     iconCls: 'fa fa-cube fa-fw'
  //   }, {
  //     path: '/smoke/alarm/list',
  //     component: SmokeAlarmList,
  //     name: '当前报警',
  //     menuShow: true,
  //     iconCls: 'fa fa-cube fa-fw'
  //   }, {
  //     path: '/smoke/alarm/count',
  //     component: SmokeAlarmCount,
  //     name: '累计报警',
  //     menuShow: true,
  //     iconCls: 'fa fa-cube fa-fw'
  //   }]
  // }, 
  {
    path: '/inspect',
    component: Home,
    name: '远程设施巡检',
    leaf: true,
    menuShow: true,
    iconCls: 'fa fa-eye fa-fw',
    children: [{
      path: '/inspect/list',
      component: InspectionList,
      name: '远程设施巡检',
      menuShow: true,
      iconCls: 'fa fa-eye fa-fw'
    }, {
      path: '/inspect/point/qrCode',
      component: InspectionPointQrCode,
      name: '巡检点二维码',
      menuShow: false,
      iconCls: 'fa fa-eye fa-fw'
    }]
  }, 
  // {
  //   path: '/dutyInsepction',
  //   component: Home,
  //   name: '人工智能巡查',
  //   menuShow: true,
  //   iconCls: 'fa fa-crosshairs fa-fw',
  //   children: [{
  //     path: '/dutyInspectPoint/list',
  //     component: DutyInspectPoint,
  //     name: '岗位巡查',
  //     menuShow: true,
  //     iconCls: 'fa fa-bullseye fa-fw'
  //   }, {
  //     path: '/dutyInspectRecord/list',
  //     component: DutyInspectRecord,
  //     name: '岗位巡查',
  //     menuShow: false,
  //     iconCls: 'fa fa-bullseye fa-fw'
  //   }, {
  //     path: '/fpRoadInspectPoint/list',
  //     component: FpRoadInspectPoint,
  //     name: '消防通道巡查',
  //     menuShow: true,
  //     iconCls: 'fa fa-road fa-fw'
  //   }, {
  //     path: '/fpRoadInspectRecord/list',
  //     component: FpRoadInspectRecord,
  //     name: '消防通道巡查',
  //     menuShow: false,
  //     iconCls: 'fa fa-road fa-fw'
  //   }]
  // }, 
  // {
  //   path: '/safe',
  //   component: Home,
  //   name: '生产安全监控',
  //   menuShow: true,
  //   iconCls: 'fa fa-shield fa-fw',
  //   children: [{
  //     path: '/report/monitor/dust/performance/data',
  //     component: MonitorDustPerformanceData,
  //     name: '粉尘',
  //     menuShow: true,
  //     iconCls: 'fa fa-tachometer fa-fw'
  //   }, {
  //     path: '/report/monitor/water/level/performance/data',
  //     component: MonitorWaterLevelPerformanceData,
  //     name: '水位',
  //     menuShow: true,
  //     iconCls: 'fa fa-sort fa-fw'
  //   }, {
  //     path: '/report/monitor/temperature/performance/data',
  //     component: MonitorTemperaturePerformanceData,
  //     name: '温度',
  //     menuShow: true,
  //     iconCls: 'fa fa-thermometer fa-fw'
  //   }, {
  //     path: '/report/monitor/pressure/performance/data',
  //     component: MonitorPressurePerformanceData,
  //     name: '压力',
  //     menuShow: true,
  //     iconCls: 'fa fa-support fa-fw'
  //   }]
  // }, 
  {
    path: '/bigData',
    component: Home,
    name: '大数据分析',
    menuShow: true,
    role: 1001,
    mod: 'bigData',
    iconCls: 'fa fa-bullseye fa-fw',
    children: [{
      path: '/report/power/fixTime/chart',
      component: PowerFixTimeChart,
      name: '数据定点分析',
      menuShow: true,
      role: 1001,
      iconCls: 'fa fa-clock-o fa-fw'
    }, {
      path: '/report/power/temperatureRise/chart',
      component: PowerTemperatureRiseChart,
      name: '数据温升分析',
      menuShow: true,
      role: 1001,
      iconCls: 'fa fa-thermometer fa-fw'
    }]
  }, 
  // {
  //   path: '/alarm',
  //   component: Home,
  //   name: '告警管理',
  //   menuShow: true,
  //   iconCls: 'fa fa-sellsy fa-fw',
  //   children: [{
  //     path: '/alarm/realTime',
  //     component: DeviceAlarmRealTime,
  //     name: '实时告警',
  //     menuShow: true,
  //     iconCls: 'fa fa-binoculars fa-fw'
  //   }, {
  //     path: '/alarm/history',
  //     component: DeviceAlarmHistory,
  //     name: '历史告警',
  //     menuShow: true,
  //     iconCls: 'fa fa-file-text fa-fw'
  //   }]
  // }, 
  // {
  //   path: '/device',
  //   component: Home,
  //   name: '设备管理',
  //   menuShow: true,
  //   iconCls: 'fa fa-archive fa-fw',
  //   children: [
  //   // {
  //   //   path: '/device/register',
  //   //   component: Developing,
  //   //   name: '设备入网',
  //   //   menuShow: true,
  //   //   iconCls: 'fa fa-plug fa-fw'
  //   // }, 
  //   {
  //     path: '/device/list',
  //     component: DeviceList,
  //     name: '设备列表',
  //     menuShow: true,
  //     iconCls: 'fa fa-list fa-fw'
  //   }, {
  //     path: '/setting/alarm/threshold',
  //     component: PowerAlarmThreshold,
  //     name: '告警阈值',
  //     menuShow: true,
  //     iconCls: 'fa fa-bell fa-fw'
  //   }]
  // }, 
  {
    path: '/setting',
    component: Home,
    name: '系统设置',
    menuShow: true,
    iconCls: 'fa fa-cog fa-fw',
    children: [{
      path: '/setting/user',
      component: UserList,
      name: '用户管理',
      menuShow: true,
      role: 1001,
      iconCls: 'fa fa-users fa-fw'
    }, {
      path: '/setting/my',
      component: MyInfo,
      name: '个人信息',
      menuShow: true,
      iconCls: 'fa fa-user fa-fw'
    }, {
      path: '/merchant/list',
      component: MerchantList,
      name: '组织管理',
      menuShow: true,
      role: 1001,
      iconCls: 'fa fa-institution fa-fw'
    }, {
      path: '/merchant/add',
      component: MerchantAdd,
      name: '开通组织',
      menuShow: false,
      role: 1001,
      iconCls: 'fa fa-plus-square fa-fw'
    }, {
      path: '/operationLog',
      component: OperationLog,
      name: '操作日志',
      menuShow: true,
      role: 1001,
      iconCls: 'fa fa-file-text-o fa-fw'
    }, {
      path: '/setting/system',
      component: Developing,
      name: '参数配置',
      menuShow: false,
      iconCls: 'fa fa-cog fa-fw'
    }, {
      path: '/fire/device/add',
      component: FireDeviceAdd,
      name: '火警控制器设备配置',
      menuShow: false,
      iconCls: 'fa fa-fire fa-fw'
    }]
  }, {
    path: '/video',
    component: Home,
    name: '视频',
    menuShow: false,
    iconCls: 'fa fa-cog fa-fw',
    children: [{
      path: '/video/monitor',
      component: VideoMonitor,
      name: '视频监控',
      menuShow: false
    }]
  }, {
    path: '/*', // otherwise redirect to dashboard
    redirect: '/dashboard'
  }]
})

export default router
