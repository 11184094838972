import { render, staticRenderFns } from "./AreaEditor.vue?vue&type=template&id=7398fafc&scoped=true&"
import script from "./AreaEditor.vue?vue&type=script&lang=js&"
export * from "./AreaEditor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7398fafc",
  null
  
)

export default component.exports