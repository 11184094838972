<template>
  <div style="padding: 15px;">
    <!-- <h3 class="text-primary">电表性能报告查询</h3> -->
    <div class="query-bar">
      <el-select v-model="deviceOpenId" filterable placeholder="请选择设备" style="width: 260px;">
        <el-option v-for="item in deviceList" :key="item.openId" :label="item.name" :value="item.openId"></el-option>
      </el-select>
    </div>
    <div style="width: 600px; margin: 100px auto 0 auto;">
      <el-input
        type="textarea"
        :rows="10"
        placeholder="每行一条记录; 格式：回路 地址 名称 设备类型"
        v-model="textAdd">
      </el-input>
      <br/>
      <br/>
      <el-button type="primary" @click="submit" :loading="submitLoading">提交</el-button>
    </div>
  </div>
</template>

<script>
import { deviceList, fireDeviceAdd } from '@/api/api'

export default {
  data () {
    return {
      deviceList: [],
      deviceOpenId: '',
      textAdd: '',
      submitLoading: false
    }
  },
  methods: {
    load () {
    },
    submit () {
      let fireDeviceList = []
      this.textAdd.trim().split('\n').forEach(line => {
        let splits = line.trim().split(/\s/)
        if (splits.length === 4) {
          fireDeviceList.push({
            loopId: parseInt(splits[0]),
            addressId: parseInt(splits[1]),
            name: splits[2],
            type: parseInt(splits[3])
          })
        }
      })
      this.$confirm(`检测到 ${fireDeviceList.length} 个有效信息，是否提交?`, '提示', {
      }).then(async () => {
        if (fireDeviceList.length === 0) return
        let successCount = 0
        this.submitLoading = true
        for (let item of fireDeviceList) {
          let param = Object.assign({deviceOpenId: this.deviceOpenId}, item)
          await fireDeviceAdd(param, {}).then((data) => {
            if (data.resCode !== undefined && data.resCode === '0') {
              successCount++
            }
          }).catch(() => {})
        }
        this.submitLoading = false
        this.$alert(`添加完成： ${successCount}`)
      })
    }
  },
  created () {
    // init device list
    deviceList({type: 3}).then((data) => {
      this.deviceList = data.result
      if (this.$route.query.deviceOpenId) {
        this.deviceOpenId = this.$route.query.deviceOpenId
      }
      if (!this.deviceOpenId) {
        let lastSelecteDevice = localStorage.getItem('hudson.power.lastSelectDevice')
        if (lastSelecteDevice) {
          for (let item of this.deviceList) {
            if (lastSelecteDevice === item.openId) this.deviceOpenId = lastSelecteDevice
          }
        }
      }
      if (!this.deviceOpenId && this.deviceList[0]) this.deviceOpenId = this.deviceList[0].openId

      this.load()
    })
  }
}
</script>
