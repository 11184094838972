<template>
  <div style="padding: 15px;">
    <el-row>
      <el-col :span="24" class="query-bar">
        <el-form ref="form" :model="queryform" :inline="true" label-width="100px">
          <el-form-item label="用户类型" style="margin-bottom: 0;">
            <el-select v-model="query.userType" @change="load">
              <el-option v-for="item in userTypeOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="关键词">
            <el-input v-model="query.keyword" placeholder="姓名/手机号"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click.prevent="load"><i class="fa fa-search fa-fw"></i></el-button>
          </el-form-item> -->
          <el-button type="success" icon="fa fa-user-plus fa-fw" style="float:right;" @click="addUserDialogVisible=true"> 新增用户</el-button>
          <el-tooltip placement="bottom" style="float:right; margin-right: 10px;">
            <div slot="content">管理员：拥有管理权限<br />告警通知：接收告警通知<br /></div>
            <el-button type="text" icon="fa fa-users fa-fw" > 用户角色说明</el-button>
          </el-tooltip>
        </el-form>
      </el-col>
      <!-- 新增用户弹出框 -->
      <el-dialog
        title="新增用户"
        :visible.sync="addUserDialogVisible"
        width="500px">
        <div>
          <el-form :model="addUserForm"
            ref="addUserForm"
            :rules="addUserRules"
            status-icon label-position="right" label-width="100px"
            :inline="false" style="width: 400px;">
            <el-form-item label="姓名" required prop="name">
              <el-input v-model="addUserForm.name" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item label="手机号" required prop="mobile">
              <el-input v-model="addUserForm.mobile" auto-complete="off"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addUserDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addUserSubmit" :loading="addUserLoading">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 修改用户信息弹出框 -->
      <el-dialog
        title="修改用户信息"
        :visible.sync="modifyUserDialogVisible"
        width="500px">
        <div>
          <el-form :model="modifyUserInfo"
            ref="modifyUserForm"
            :rules="modifyUserRules"
            status-icon label-position="right" label-width="100px"
            :inline="false" style="width: 400px;">
            <el-form-item label="姓名" required prop="name">
              <el-input v-model="modifyUserInfo.name" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item label="手机号" required prop="mobile">
              <el-input v-model="modifyUserInfo.mobile" auto-complete="off"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="modifyUserDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="modifyUserSubmit" :loading="modifyUserLoading">确 定</el-button>
        </span>
      </el-dialog>

      <el-col :span="24" class="main-content">
        <el-table
          :data="userList"
          v-loading="loading"
          stripe
          border
          style="width: 100%">
          <!-- <el-table-column
            type="index"
            label=""
            width="60">
          </el-table-column> -->
          <el-table-column
            prop="name"
            label="姓名"
            width="80">
          </el-table-column>
          <el-table-column
            prop="mobile"
            label="手机号"
            width="120">
          </el-table-column>
          <el-table-column
            label="角色">
            <template slot-scope="scope">
              <template v-for="item in scope.row.roleList">
                <el-tag v-for="item2 in item.roleDesc" :key="item2.value" 
                  :type="item2.value > 0 ? '' : 'info'" :closable="item2.value > 0" 
                  @close="removeRole(scope.row, item2)"
                  size="small" style="margin-bottom: 5px;"
                  >{{item2.name}}</el-tag>
              </template>
              <el-dropdown class="hover-hide" @command="addRole">
                <el-button size="small" type="text" plain> <i class="fa fa-plus fa-fw"></i> </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="{user: scope.row, roleValue: 1}">管理员</el-dropdown-item>
                  <el-dropdown-item :command="{user: scope.row, roleValue: 2}">告警通知</el-dropdown-item>
                  <el-dropdown-item :command="{user: scope.row, roleValue: 3}">工单管理员</el-dropdown-item>
                  <el-dropdown-item :command="{user: scope.row, roleValue: 4}">处理工单人员</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
          <el-table-column
            prop="statusDesc"
            label="状态"
            width="80">
          </el-table-column>
          <el-table-column
            label="操作"
            width="200">
            <template slot-scope="scope">
              <el-button size="small" type="danger" @click="deleteUser(scope.row)">删除</el-button>
              <el-button size="small" type="primary" @click="modifyUser(scope.row)">修改</el-button>
          </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import store from '../util/store'
  import { merchantUserList, merchantUserAdd, merchantUserRoleAdd, merchantUserRoleDelete, merchantUserDelete, merchantUserModify } from '../api/api'

  export default {
    data () {
      var mobileValidator = (rule, value, callback) => {
        if (/^1\d{10}$/.test(value)) {
          return callback()
        } else {
          return callback(new Error('手机号格式不正确'))
        }
      }
      return {
        queryform: {},
        query: {
          userType: 0,
          keyword: ''
        },
        userList: [],
        addUserLoading: false,
        modifyUserLoading: false,
        loading: true,
        userTypeOptions: [{id: 0, name: '全部'}, {id: 1, name: '管理员'}, {id: 2, name: '告警通知'}, {id: 3, name: '工单管理员'}, {id: 4, name: '处理工单人员'}],
        addUserDialogVisible: false,
        addUserForm: {},
        addUserRules: {
          name: [
            { required: true, message: '请输入姓名', trigger: 'blur' },
            { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
          ],
          mobile: [
            { required: true, message: '请输入手机号', trigger: 'blur' },
            { min: 11, max: 11, message: '手机号长度需为11位', trigger: 'blur' },
            { validator: mobileValidator, trigger: 'blur' }
          ]
        },
        modifyUserDialogVisible: false,
        modifyUserInfo: {},
        modifyUserRules: {
          name: [
            { required: true, message: '请输入姓名', trigger: 'blur' },
            { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
          ],
          mobile: [
            { required: true, message: '请输入手机号', trigger: 'blur' },
            { min: 11, max: 11, message: '手机号长度需为11位', trigger: 'blur' },
            { validator: mobileValidator, trigger: 'blur' }
          ]
        }
      }
    },
    computed: {
    },
    methods: {
      load () {
        this.loading = true
        let params = {
          roleId: this.query.userType
        }
        merchantUserList(params).then(data => {
          if (data.resCode === '0') {
            this.userList = data.result
          }
        }).finally(() => {
          this.loading = false
        })
      },
      addUserSubmit () {
        this.$refs['addUserForm'].validate((valid) => {
          if (valid) {
            this.addUserLoading = true
            let data = this.addUserForm
            merchantUserAdd({}, data).then(data => {
              this.$message({
                message: '添加成功',
                type: 'success'
              })
              this.addUserDialogVisible = false
              this.addUserForm = {}
              this.load()
            }).finally(() => {
              this.addUserLoading = false
            })
          } else {
            this.$message('请检查填写的数据！')
            return false
          }
        })
      },
      modifyUserSubmit () {
        this.$refs['modifyUserForm'].validate((valid) => {
          if (valid) {
            this.modifyUserLoading = true
            let data = this.modifyUserInfo
            merchantUserModify({}, data).then(data => {
              this.$message({
                message: '修改成功',
                type: 'success'
              })
              this.modifyUserDialogVisible = false
              this.modifyUserInfo = {}
              this.load()
            }).finally(() => {
              this.modifyUserLoading = false
            })
          } else {
            this.$message('请检查填写的数据！')
            return false
          }
        })
      },
      removeRole (user, role) {
        if (user.openId === store.userInfo.openId && role.value === 1) {
          this.$message({
            type: 'warning',
            message: '不允许操作自己的运营者权限!'
          })
          return
        }
        this.$confirm(`此操作将移除[${user.name}]的[${role.name}]角色, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let params = {
            userOpenId: user.openId,
            roleValue: role.value
          }
          merchantUserRoleDelete(params).then(data => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.load()
          })
        }).catch(() => {
        })
      },
      addRole (command) {
        let params = {
          userOpenId: command.user.openId,
          roleValue: command.roleValue
        }
        merchantUserRoleAdd(params).then(data => {
          this.$message({
            message: '添加成功',
            type: 'success'
          })
          this.load()
        })
      },
      deleteUser (user) {
        this.$confirm(`是否确认删除[${user.name}]？`).then(() => {
          merchantUserDelete({userOpenId: user.openId}, {}).then(() => {
            this.load()
          })
        })
      },
      modifyUser (user) {
        this.modifyUserInfo = user
        this.modifyUserDialogVisible = true
      }
    },
    created () {
      this.load()
    }
  }
</script>
<style lang="less" scoped>
  .el-tag + .el-tag {
    margin-left: 10px;
  }

  // .el-table tr .hover-hide {
  //   display: none;
  // }
  // .el-table tr:hover .hover-hide {
  //   display: inline-block;
  // }


</style>
