<template>
  <div style="padding: 15px;">
    <el-row>
      <el-col :span="24" class="query-bar">
        <el-form ref="form" :model="queryform" :inline="true" label-width="100px">

          <!-- <el-form-item label="关键词">
            <el-input v-model="query.keyword" placeholder="名称"></el-input>
          </el-form-item> -->
          <el-form-item style="margin-bottom: 0;">
            <el-button type="primary" native-type="submit" @click.prevent="load"><i class="fa fa-search fa-fw"></i></el-button>
          </el-form-item>
        </el-form>

      </el-col>
      
      <el-col :span="24" class="main-content">
        <el-table
          :data="logList"
          v-loading="loading"
          stripe
          border
          style="width: 100%">
          <el-table-column
            type="index"
            label=""
            width="50">
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="时间"
            width="200">
            <template slot-scope="scope">{{scope.row.createTime | date('YYYY-MM-DD HH:mm:ss')}}</template>
          </el-table-column>
          <el-table-column
            prop="operatorInfo"
            label="用户"
            min-width="50">
          </el-table-column>
          <el-table-column
            prop="merchantName"
            label="组织"
            min-width="50">
          </el-table-column>
          <el-table-column
            prop="info"
            label="内容"
            min-width="100">
          </el-table-column>
          <el-table-column
            prop="count"
            label="统计"
            min-width="30">
          </el-table-column>
          <!-- <el-table-column
            prop="method"
            label="调用"
            min-width="200">
          </el-table-column> -->
        </el-table>
      </el-col>
      <el-col :span="24" class="tool-bar">
        <el-pagination @current-change="load" @size-change="load" :current-page.sync="pageData.pageNo" :page-sizes="[20, 50, 100]" :page-size.sync="pageData.pageSize" layout="total, sizes, prev, pager, next" :total="pageData.total" style="float: right;">
        </el-pagination>
      </el-col>
    </el-row>


  </div>
</template>

<script>
import { merchantOperationLog } from '../api/api'

export default {
  data () {
    return {
      queryform: {},
      query: {
        keyword: '',
        areaList: []
      },
      pageData: {
        pageNo: 1,
        pageSize: 20,
        total: 0
      },
      logList: [],
      loading: true
    }
  },
  computed: {
  },
  methods: {
    load () {
      this.loading = true
      let params = {
        keyword: this.query.keyword,
        pageNo: this.pageData.pageNo - 1,
        pageSize: this.pageData.pageSize
      }
      merchantOperationLog(params).then(data => {
        this.logList = data.result.content
        this.pageData.total = data.result.totalElements
      }).finally(() => {
        this.loading = false
      })
    }
  },
  created () {
    this.load()
  }
}
</script>
<style lang="less" scoped>


</style>
