<template>
  <div style="padding: 15px;">
    <!-- <h3 class="text-primary">电表性能报告查询</h3> -->
    <div class="query-bar clearfix no-print">
      <el-form :inline="true" label-width="100px">
        <el-form-item label="数量" style="margin-bottom: 0;">
          <el-input-number v-model="query.quantity" :min="1" :max="100" placeholder="数量"></el-input-number>
        </el-form-item>
        <el-button type="primary" native-type="submit" @click.native.prevent="load">
          <i class="fa fa-qrcode"></i> 生成
        </el-button>
        
        <el-form-item label="布局" style="margin-bottom: 0;">
          <el-radio-group v-model="colspan">
            <el-radio-button :label="12">2列</el-radio-button>
            <el-radio-button :label="8">3列</el-radio-button>
            <el-radio-button :label="6">4列</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-button style="float: right;" type="primary" icon="fa fa-print" @click="print"> 打印</el-button>
      </el-form>
    </div>

    <div class="clearfix">
      <el-row>
        <el-col :span="colspan" v-for="item in resultList" :key="item" >
          <div class="qrcode-container" style="width: 256px; height: 256px;">
            <qrcode :body="item" style="width: 100%; height: 100%;"></qrcode>
            <div class="qrcode-info">扫一扫 来巡检</div>
            <div class="qrcode-logo"><img src="../assets/logo.png"></img></div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import qrcode from '@/components/QrCode.vue'
import {inspectionNewQrCode} from '@/api/api'
export default {
  components: {
    qrcode
  },
  data () {
    this.chartSettings = {}
    return {
      query: {
        quantity: 20
      },
      colspan: 12,
      resultList: []
    }
  },
  methods: {
    load () {
      inspectionNewQrCode(this.query).then((data) => {
        this.resultList = data.result
      })
    },
    print () {
      window.print()
    }
  },
  created () {
  }
}
</script>
<style lang="scss" scoped>
.qrcode-container {
  border: solid 2px #1156a1;
  border-radius: 10px;
  padding: 5px;
  position: relative;
  margin: 20px;
}
.qrcode-info {
  position: absolute;
  top: 10px;
  left: 100%;
  width: 20px;
  padding: 5px 5px 20px 5px;
  background-color: #1156a1;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 30px;
}
.qrcode-logo {
  padding: 2px;
  background-color: #fff;
  img {
    width: 48px;
    height: 48px;
  }
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>

