// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
// import 'babel-polyfill'
import Vue from 'vue'
import moment from 'moment'
import 'moment-precise-range-plugin'
import App from './App'
import router from './router'
import ElementUI from 'element-ui'
import '@/style/element-ui.scss'
import '@/style/main.scss'

// 引入 ECharts 主模块
// import ECharts from 'vue-echarts/components/ECharts.vue'
import ECharts from 'vue-echarts/components/ECharts'
// import 'echarts/lib/chart/bar'
// import 'echarts/lib/chart/line'
// import 'echarts/lib/chart/pie'
// import 'echarts/lib/chart/map'
// import 'echarts/lib/chart/gauge'
// import 'echarts/lib/component/tooltip'
// import 'echarts/lib/component/toolbox'
// import 'echarts/lib/component/title'
// import 'echarts/lib/component/legendScroll'
import 'echarts/extension/bmap/bmap'
import dataV from '@jiaminghi/data-view'

Vue.component('chart', ECharts)

Vue.use(dataV)

Vue.config.productionTip = false

Vue.use(ElementUI)

// filters
// <h1>{{new Date() | date}}</h1>
// <h1>{{new Date() | date('date')}}</h1>
// <h1>{{new Date() | date('time')}}</h1>
// <h1>{{new Date() | date('HH:mm')}}</h1>
Vue.filter('date', function (date, formatString = 'YYYY-MM-DD HH:mm:ss', isUTC) {
  if (formatString === 'date') formatString = 'YYYY-MM-DD'
  else if (formatString === 'time') formatString = 'HH:mm:ss'
  return isUTC ? moment(date).utc().format(formatString) : moment(date).format(formatString)
})

// timeDesc
// <h1>{{new Date() | timeDesc}}</h1>
Vue.filter('timeDesc', function (date, isUTC) {
  let moumentValue = isUTC ? moment(date).utc() : moment(date)
  let now = moment()
  let diff = moment.preciseDiff(moumentValue, now, true)
  let diffStr = ''
  if (diff.years > 0) diffStr = diff.years + '年'
  else if (diff.months > 0) diffStr = diff.months + '月'
  else if (diff.days > 0) diffStr = diff.days + '天'
  else if (diff.hours > 0) diffStr = diff.hours + '小时'
  else if (diff.minutes > 0) diffStr = diff.minutes + '分钟'
  else if (diff.seconds > 0) diffStr = diff.seconds + '秒'

  return diffStr + (diff.firstDateWasLater ? '后' : '前')
})

Object.defineProperty(Vue.prototype, '$filter', {
  value: {
    date: Vue.filter('date'),
    timeDesc: Vue.filter('timeDesc')
  }
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  components: { App },
  template: '<App/>'
})
