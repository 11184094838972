<template>
  <div style="padding: 15px;">
    <el-form ref="merchantAddForm" :model="merchantAddForm" :rules="merchantAddFormRules" label-width="100px" style="width: 500px;">
      <h4>新增组织</h4>
      <el-form-item label="组织名称" prop="name" required>
        <el-input v-model="merchantAddForm.name" placeholder="请输入组织名称"></el-input>
      </el-form-item>
      <el-form-item label="管理员角色" prop="initManagerRole">
        <el-checkbox v-model="merchantAddForm.initManagerRole">自动添加平台管理员的角色</el-checkbox>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="merchantAddClick" :loading="submitLoading">确定</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { merchantAdd } from '../api/api'

export default {
  data () {
    return {
      merchantAddForm: {
        name: '',
        initManagerRole: true
      },
      merchantAddFormRules: {
        name: [
          { required: true, message: '请输入商户简称', trigger: 'blur' },
          { min: 2, max: 20, message: '长度应为2-20', trigger: 'blur' }
        ]
      },
      submitLoading: false
    }
  },
  methods: {
    merchantAddClick () {
      this.$refs['merchantAddForm'].validate((valid) => {
        if (valid) {
          this.submitLoading = true
          merchantAdd(this.merchantAddForm, '').then((data) => {
            this.merchantAddForm = {
              name: '',
              plan: 1,
              initManagerRole: true
            }
            this.$refs['merchantAddForm'].clearValidate()
            this.$confirm(`账户[${data.result.name}]已开通! 现在切换到该组织？`)
              .then((action) => {
                if (!data.result) return
                localStorage.setItem('hudson.power.selectMerchantOpenId', data.result.openId)
                setTimeout(() => {
                  this.$router.push('/login')
                }, 500)
              }).catch((action) => {
                location.reload()
              })
          }).finally(() => {
            this.submitLoading = false
          })
        }
      })
    }
  }
}
</script>
