<template>
  <div style="padding: 15px;">
    <!-- <h3 class="text-primary">电表性能报告查询</h3> -->
    <div class="query-bar">
      状态 
      <el-select v-model="query.status" @change="deviceSelected" filterable placeholder="请选择设备" style="width: 120px;">
        <el-option label="-全部-" :value="-1"></el-option>
        <el-option label="正常" :value="0"></el-option>
        <el-option label="故障" :value="1"></el-option>
        <el-option label="丢失" :value="2"></el-option>
        <el-option label="其他" :value="100"></el-option>
        <!-- <el-option v-for="item in statusList" :key="item.openId" :label="item.name" :value="item.openId"></el-option> -->
      </el-select>

      <el-date-picker v-model="query.dateRange" type="daterange" value-format="timestamp" align="center" :clearable="false" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="queryDatePickerOptions">
      </el-date-picker>

      <el-button type="primary" @click.prevent="load">
        <i class="fa fa-search"></i>
      </el-button>
      <a href="#/inspect/point/qrCode">
        <el-button style="float: right;" type="success" icon="fa fa-qrcode"> 生成巡检点二维码</el-button>
      </a>
    </div>
    <div class="clearfix">
      <el-table :data="inspectionList" border>
        <el-table-column type="index" width="40" :fixed="true">
        </el-table-column>
        <el-table-column prop="point.name" label="巡检点名称">
        </el-table-column>
        <el-table-column label="巡检点位置" width="100">
          <template slot-scope="scope">
            <el-tooltip>
              <i class="fa fa-map-marker"></i>
              <div slot="content">{{scope.row.point.longitude}}, {{scope.row.point.latitude}}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="上报位置" width="100">
          <template slot-scope="scope">
            <el-tooltip>
              <i class="fa fa-map-marker"></i>
              <div slot="content">{{scope.row.longitude}}, {{scope.row.latitude}}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="上报距离" width="100">
          <template slot-scope="scope">{{scope.row.distance ? scope.row.distance.toFixed(3): 0}}km</template>
        </el-table-column>
        <el-table-column label="巡检现场" width="120">
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.image1">
              <i class="fa fa-image"></i>
              <div slot="content"><img :src="scope.row.image1" width="300px" style="max-heigth: 600px;"></img></div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="statusDesc" label="巡检状态" width="100">
        </el-table-column>
        <el-table-column prop="statusRemark" label="巡检备注信息">
        </el-table-column>
        <el-table-column prop="createTime" label="时间" width="140" :fixed="true">
          <template slot-scope="scope">{{scope.row.createTime | date('YYYY-MM-DD HH:mm:ss')}}</template>
        </el-table-column>
      </el-table>
      <el-pagination @current-change="load" @size-change="load" :current-page.sync="pageData.pageNo" :page-size.sync="pageData.pageSize" :page-sizes="[10, 20, 50, 100]" layout="total, prev, pager, next" :total="pageData.total" style="float: right;">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { inspectionRecordList } from '@/api/api'

export default {
  data () {
    return {
      query: {
        status: -1,
        deviceOpenId: '',
        dateRange: [new Date().getTime() - 3600 * 1000 * 24 * 7, new Date().getTime() + 3600 * 1000 * 24]
      },
      queryDatePickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick (picker) {
            const today = moment({ hour: 0 })
            const tomorrow = moment({ hour: 0 }).add(1, 'days')
            const start = today.toDate()
            const end = tomorrow.toDate()
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一天',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近两天',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 2)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      pageData: {
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      deviceList: [],
      inspectionList: [],
      downloadTask: {},
      exportLoading: false
    }
  },
  methods: {
    load () {
      let params = {
        status: this.query.status,
        startTime: this.query.dateRange[0],
        endTime: this.query.dateRange[1],
        pageNo: this.pageData.pageNo - 1,
        pageSize: this.pageData.pageSize
      }
      inspectionRecordList(params).then((data) => {
        if (data.result.content && data.result.content.length > 0) {
          this.inspectionList = data.result.content
          this.pageData.total = data.result.totalElements
        } else {
          this.inspectionList = []
          this.$message.warning('该时间段暂无数据！')
        }
      })

      this.downloadTask = {}
      this.exportLoading = false
    },
    deviceSelected () {
      this.$router.push({ query: { deviceOpenId: this.query.deviceOpenId } })
      localStorage.setItem('hudson.power.lastSelectDevice', this.query.deviceOpenId)
    }
  },
  created () {
    this.load()
  }
}
</script>
