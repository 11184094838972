<template>
  <div class="box">
    <div class="hd">哈德森用电/能耗/生产/消防监控云平台</div>
    <div class="bd">
      <div style="height: 100px; width: 100%; display: flex;" class="number-bar">
        <div style="flex: 1;" class="nbox">
          <h4 class="label">智能电表告警</h4>
          <h4 class="value">{{10}}</h4>
        </div>
        <div style="flex: 1;" class="nbox">
          <h4 class="label">工单完成比率</h4>
          <h4 class="value">90%</h4>
        </div>
        <div style="flex: 1;" class="nbox">
          <h4 class="label">远程查岗在岗率</h4>
          <h4 class="value">99%</h4>
        </div>
        <div style="flex: 1;" class="nbox">
          <h4 class="label">巡检完成比率</h4>
          <h4 class="value">10%</h4>
        </div>
      </div>
    </div>
    <dv-decoration-2 style="height:10px;" />
  </div>
</template>

<script>
// import ecStat from 'echarts-stat'
/* eslint-disable */
import * as echarts from 'echarts'
export default {
  name: 'MidChart1',
  data () {
    return {}
  },
  methods: {
    load() {
        this.drawGenderChart()
    },
    drawGenderChart() {
        var myChart = echarts.init(document.getElementById("income"), 'dark');
        var option
        option = {
            legend: {
                data: ['收入', '支出'],
                orient: 'vertical',
                left: 'left',
            },
            radar: {
                // shape: 'circle',
                name: {
                    textStyle: {
                        color: '#fff',
                        backgroundColor: '#999',
                        borderRadius: 3,
                        padding: [3, 5]
                    }
                },
                indicator: [
                    { name: '类型一', max: 6500},
                    { name: '类型二', max: 16000},
                    { name: '类型三', max: 30000},
                    { name: '类型四', max: 38000},
                    { name: '类型五', max: 52000},
                ]
            },
            series: [{
                name: '收入 vs 支出',
                type: 'radar',
                // areaStyle: {normal: {}},
                data: [
                    {
                        value: [4300, 10000, 28000, 35000, 50000, 19000],
                        name: '收入'
                    },
                    {
                        value: [5000, 14000, 28000, 31000, 42000, 21000],
                        name: '支出',
                        areaStyle: {
                            color: 'rgba(255, 255, 255, 0.5)'
                        }
                    }
                ]
            }]
        };
        option && myChart.setOption(option);
    }
  },
  mounted() {
    this.load()
  }
}
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 0;
  flex-direction: column;

  .hd {
    text-align: center;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .bd {
    flex:1;
    height: 50px;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-indent: 20px;

    span {
      color: #096dd9;
      font-weight: bold;
      font-size: 35px;
      margin-left: 20px;
    }
  }

  .chart {
    flex: 1;
  }
}

.nbox {
  padding: 10px;
  h4 {
    margin: 0;
    padding: 0;
    text-align: center;
  }
  h4.label {
    height: 30px;
    font-size: 20px;
    line-height: 30px;
    color: #25C6FC;
  }
  h4.value {
    height: 50px;
    font-size: 36px;
    font-weight: 800;
    line-height: 50px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: #fff;
  }
}


.number-bar {
  background-color: #0f1e3e;
  box-sizing: border-box;
  border: solid 1px #25C6FC;
  border-radius: 10px;
  margin: 10px;
  box-shadow: 0 0 10px 0 #25C6FC inset;
}

ul {
  padding: 0 1em;
  list-style: none;
  li {
    font-size: 1em;
    padding: 3px 0;
    font-weight: bold;
    a {
      color: #fff;
    }
    .label {
      display: inline-block;
      margin-right: 10px;
      color:#25c6fc;
    }
  }
}
</style>