<template>
  <div>
    <div class="query-bar clearfix">
      <span style="line-height: 2; font-size: 18px; color: #333;">{{device.name}}</span>
      <el-button style="float: right;" @click="udateStatus"><i class="fa fa-refresh"></i></el-button>
    </div>
    <el-row class="pannel" v-loading="loading">
      <el-col :span="6">
        <div class="switch-pannel">
          <h4>DI1</h4>
          <div style="height: 48px;">
            <el-switch
              v-model="status.DI1.value"
              :active-value="1"
              :inactive-value="0"
              active-color="#13ce66"
              inactive-color="#ff4949"
              disabled="disabled"
              class="power-switch"
              v-if="status.DI1">
            </el-switch>
          </div>
          <div><span v-if="status.DI1">{{status.DI1.name}}</span></div>
          <div class="io-line"><i class="fa fa-long-arrow-up fa-4x"></i></div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="switch-pannel">
          <h4>DI2</h4>
          <div style="height: 48px;">
            <el-switch
              v-model="status.DI2.value"
              :active-value="1"
              :inactive-value="0"
              active-color="#13ce66"
              inactive-color="#ff4949"
              disabled="disabled"
              class="power-switch"
              v-if="status.DI2">
            </el-switch>
          </div>
          <div><span v-if="status.DI2">{{status.DI2.name}}</span></div>
          <div class="io-line"><i class="fa fa-long-arrow-up fa-4x"></i></div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="switch-pannel">
          <h4>DO1</h4>
          <div style="height: 48px;">
            <el-switch
              v-model="status.DO1.value"
              :active-value="1"
              :inactive-value="0"
              active-color="#13ce66"
              inactive-color="#ff4949"
              class="power-switch"
              v-if="status.DO1"
              @change="setSwitch('DO1')"
              >
            </el-switch>
          </div>
          <div><span v-if="status.DO1">{{status.DO1.name}}</span></div>
          <div class="io-line"><i class="fa fa-long-arrow-down fa-4x"></i></div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="switch-pannel">
          <h4>DO2</h4>
          <div style="height: 48px;">
            <el-switch
              v-model="status.DO2.value"
              :active-value="1"
              :inactive-value="0"
              active-color="#13ce66"
              inactive-color="#ff4949"
              class="power-switch"
              v-if="status.DO2"
              @change="setSwitch('DO2')"
              >
            </el-switch>
          </div>
          <div><span v-if="status.DO2">{{status.DO2.name}}</span></div>
          <div class="io-line"><i class="fa fa-long-arrow-down fa-4x"></i></div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { deviceDetail, devicePowerSwitchUpdateStatus, devicePowerSwitchSet } from '@/api/api'

export default {
  name: 'PowerSwitchPannel',
  props: {
    deviceOpenId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      device: {},
      status: {},
      loading: true
    }
  },
  watch: {
    deviceOpenId () {
      this.load()
    }
  },
  methods: {
    load () {
      if (!this.deviceOpenId) return
      this.loading = true
      deviceDetail({deviceOpenId: this.deviceOpenId}).then((data) => {
        this.device = data.result
        if (data.result.switchStatus) {
          this.status = JSON.parse(data.result.switchStatus)
        }
        // this.devicePowerSwitchUpdateStatus()
      }).catch(() => {
        this.device = {}
        this.status = {}
      }).finally(() => {
        this.loading = false
      })
    },
    udateStatus () {
      this.loading = true
      devicePowerSwitchUpdateStatus({deviceOpenId: this.deviceOpenId}).then(() => {
        this.load()
      }).finally(() => {
        this.loading = false
      })
    },
    setSwitch (port) {
      if (!port || !this.status[port]) return
      let val = this.status[port].value
      this.loading = true
      devicePowerSwitchSet({deviceOpenId: this.deviceOpenId, port: port, value: val}).then(() => {
        this.load()
      }).finally(() => {
        this.loading = false
      })
    }
  },
  created () {
    this.load()
  }
}
</script>
<style lang="scss" scoped>
@import "src/style/variables";
 
.pannel {
  // background-color: #ccc;
  padding-bottom: 50px;
}
.switch-pannel {
  text-align: center;
  border: 1px solid $--color-primary;
  background-color: #fff;
  margin: 2px;
  padding: 5px;
}
.power-switch {
  transform: rotate(270deg);
}
.io-line {
  height: 20px;
  > i {
    position: relative;
    top: 5px;
    color: $--color-info;
  }
}
</style>

