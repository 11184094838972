import axios from 'axios'
import qs from 'qs'
import store from '../util/store'
import bus from '../util/bus'

const API_URL = 'https://api.hudsonpower.cn/'
// const API_URL = 'https://api-dev.hudsonpower.cn'
// const API_URL = 'http://localhost:9002/'

let createApiAxios = function () {
  let myAxios = axios.create({
    baseURL: API_URL,
    timeout: 100000,
    headers: {
      'X-Token': store.token
    }
  })

  // 请求拦截器，根据配置增加merchantOpenId参数
  myAxios.interceptors.request.use(function (config) {
    if (config.withMerchantOpenId) {
      let params = config.params || {}
      params.merchantOpenId = store.merchantInfo.merchantOpenId
      config.params = params
    }
    if (config.formData) {
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'
      config.data = qs.stringify(config.data)
    }
    return config
  }, function (error) {
    return Promise.reject(error)
  })

  // 响应拦截器，根据错误消息，在bus上发起响应事件
  myAxios.interceptors.response.use(function (response) {
    if (response.status === 200 && response.data) {
      let data = response.data
      if (data && data.resCode === '0') {
        return Promise.resolve(response.data)
      } else {
        bus.$emit('apiError', data)
        console.log(`接口错误: ${data.resDesc}`)
        return Promise.reject(new Error(`接口错误: ${data.resDesc}`))
      }
    } else {
      bus.$emit('networkError', `网络错误: ${response.status}`)
      console.log(`网络错误: ${response.status}`)
      return Promise.reject(new Error(`网络错误: ${response.status}`))
    }
  }, function (error) {
    bus.$emit('networkError', `网络错误: ${error}`)
    console.log(`网络错误: ${error}`)
    return Promise.reject(error)
  })
  return myAxios
}

let apiAxios = createApiAxios()

// 监听setToken, setMerchantInfo 事件，更新axios实例
bus.$on('setToken', (token) => {
  apiAxios = createApiAxios()
})
bus.$on('setMerchantInfo', (token) => {
  apiAxios = createApiAxios()
})

// 手机号码、验证码登录
export const mobileVerifyLogin = (params) => {
  return apiAxios.post('user/mobileVerifyLogin', {}, {
    params: params
  })
}

// 手机号码、验证码登录
export const sendMobileVerify = (params) => {
  return apiAxios.post('user/sendMobileVerify', {}, {
    params: params
  })
}

// 退出登录
export const logout = () => {
  return apiAxios.get('user/logout', {})
}

// 退出登录(同步)
export const logoutSync = () => {
  let xhr = new XMLHttpRequest()
  xhr.open('get', `${API_URL}user/logout`, false)
  xhr.setRequestHeader('X-Token', store.token)
  xhr.send()
}

// 查询我的用户信息
export const userInfo = (params) => {
  return apiAxios.get('user/info', {})
}

// 查询商户列表
export const merchantPage = (params) => {
  return apiAxios.get('merchant/page', {
    params: params
  })
}

// 查询我的商户列表
export const merchantMyPage = (params) => {
  return apiAxios.get('merchant/my/page', {
    params: params,
    withMerchantOpenId: true
  })
}

// 查询我的商户信息
export const myMerchant = (params) => {
  return apiAxios.get('merchant/my', {})
}

// 查询商户信息
export const merchantInfoDetail = (params) => {
  return apiAxios.get('merchant/info', {
    withMerchantOpenId: true
  })
}

// 设置logo和shortName
export const merchantSetInfo = (params, data) => {
  return apiAxios.post('merchant/setInfo', data, {
    params: params
  })
}

// 查询设备
export const deviceList = (params) => {
  return apiAxios.get('device/list', {
    params: params,
    withMerchantOpenId: true
  })
}

// 查询设备
export const deviceListPage = (params) => {
  return apiAxios.get('device/list/page', {
    params: params,
    withMerchantOpenId: true
  })
}

// 查询设备性能数据
export const devicePerformance = (params) => {
  return apiAxios.get('device/performance', {
    params: params,
    withMerchantOpenId: true
  })
}

// 查询设备性能数据（分页）
export const devicePerformancePage = (params) => {
  return apiAxios.get('device/performance/page', {
    params: params,
    withMerchantOpenId: true
  })
}

// 下载设备性能数据（分页）
export const devicePerformanceExportExcel = (params) => {
  return apiAxios.get('device/performance/export/excel', {
    params: params,
    withMerchantOpenId: true
  })
}

// 查询设备性能数据
export const devicePerformanceFixTime = (params) => {
  return apiAxios.get('device/performance/fixTime', {
    params: params,
    withMerchantOpenId: true
  })
}

// 查询烟感的最后性能数据
export const deviceSmokePerformanceLast = (params) => {
  return apiAxios.get('device/smoke/performance/last', {
    params: params,
    withMerchantOpenId: true
  })
}

// 查询人感的最后性能数据
export const devicepersonDetectPerformanceLast = (params) => {
  return apiAxios.get('device/personDetect/performance/last', {
    params: params,
    withMerchantOpenId: true
  })
}

// 查询人感的最后性能数据
export const deviceSmokeEventPerformance = (params) => {
  return apiAxios.get('device/smoke/eventPerformance', {
    params: params,
    withMerchantOpenId: true
  })
}

// 按状态统计设备数量
export const deviceStatisticCount = (params) => {
  return apiAxios.get('device/statistic/count', {
    params: params,
    withMerchantOpenId: true
  })
}

// 按状态统计设备数量
export const deviceFireAlarmStatisticsDay = (params) => {
  return apiAxios.get('device/fire/alarm/statistics/day', {
    params: params,
    withMerchantOpenId: true
  })
}

// 任务状态查询
export const taskDetail = (params) => {
  return apiAxios.get(`task/${params.id}`, {
  })
}

// 查询实时告警
export const deviceAlarmRealTime = (params) => {
  return apiAxios.get('device/alarm/realTime', {
    params: params,
    withMerchantOpenId: true
  })
}

// 查询历史告警
export const deviceAlarmHistory = (params) => {
  return apiAxios.get('device/alarm/history', {
    params: params,
    withMerchantOpenId: true
  })
}

// 查询告警详情
export const deviceAlarmDetail = (params) => {
  return apiAxios.get('device/alarm/detail', {
    params: params,
    withMerchantOpenId: true
  })
}

// 查询全部状态告警
export const deviceAlarmAll = (params) => {
  return apiAxios.get('device/alarm/all', {
    params: params,
    withMerchantOpenId: true
  })
}

// 人工处理告警
export const deviceAlarmClear = (params, data) => {
  return apiAxios.post('device/alarm/clear', data, {
    params: params,
    withMerchantOpenId: true,
    formData: true
  })
}

// 查询设备详情
export const deviceDetail = (params) => {
  return apiAxios.get(`device/${params.deviceOpenId}`, {
    withMerchantOpenId: true
  })
}

// 编辑设备信息
export const deviceEdit = (params, data) => {
  return apiAxios.post(`device/${params.deviceOpenId}`, data, {
    withMerchantOpenId: true
  })
}

// 注册新设备
export const deviceAdd = (params, data) => {
  return apiAxios.post(`device/add`, data, {
    withMerchantOpenId: true
  })
}

// 关联已注册设备
export const deviceLink = (params, data) => {
  return apiAxios.post(`device/link`, data, {
    params: params,
    withMerchantOpenId: true
  })
}

// 更新电表设备开关状态
export const devicePowerSwitchUpdateStatus = (params) => {
  return apiAxios.get('device/power/switch/updateStatus', {
    params: params,
    withMerchantOpenId: true
  })
}

// 设置电表设备开关
export const devicePowerSwitchSet = (params) => {
  return apiAxios.get('device/power/switch/set', {
    params: params,
    withMerchantOpenId: true
  })
}

// 更新电表设备开关状态
export const deviceRadioConnectivityUpdateStatus = (params) => {
  return apiAxios.get('device/power/radioConnectivity/updateStatus', {
    params: params,
    withMerchantOpenId: true
  })
}

// 智能烟感统计
export const deviceSmokeStatistics = (params) => {
  return apiAxios.get('device/smoke/statistics', {
    params: params,
    withMerchantOpenId: true
  })
}

// 智能烟感统计
export const deviceSmokeStatistics2 = (params) => {
  return apiAxios.get('device/smoke/statistics2', {
    params: params,
    withMerchantOpenId: true
  })
}

// 智能烟感告警统计
export const deviceSmokeAlarmCount = (params) => {
  return apiAxios.get('device/smoke/alarmCount', {
    params: params,
    withMerchantOpenId: true
  })
}

// 告警趋势统计
export const deviceAlarmStatistics = (params) => {
  return apiAxios.get('device/alarm/statistics', {
    params: params,
    withMerchantOpenId: true
  })
}

// 告警趋势周统计
export const deviceAlarmStatisticsWeek = (params) => {
  return apiAxios.get('device/alarm/statistics/week', {
    params: params,
    withMerchantOpenId: true
  })
}

// 获取商户的用户列表
export const merchantUserList = params => {
  return apiAxios.get('merchant/user/list', {
    params: params,
    withMerchantOpenId: true
  })
}

// 新增一个商户的用户
export const merchantUserAdd = (params, data) => {
  return apiAxios.post('merchant/user/add', data, {
    params: params,
    withMerchantOpenId: true
  })
}

// 修改用户信息
export const merchantUserModify = (params, data) => {
  return apiAxios.post('merchant/user/modify', data, {
    params: params,
    withMerchantOpenId: true
  })
}

// 移除一个商户的用户
export const merchantUserDelete = (params, data) => {
  return apiAxios.post('merchant/user/delete', data, {
    params: params,
    withMerchantOpenId: true
  })
}

// 新增一个商户的用户角色
export const merchantUserRoleAdd = (params, data) => {
  return apiAxios.post('merchant/user/role/add', data, {
    params: params,
    withMerchantOpenId: true
  })
}

// 删除一个商户的用户角色
export const merchantUserRoleDelete = (params, data) => {
  return apiAxios.post('merchant/user/role/delete', data, {
    params: params,
    withMerchantOpenId: true
  })
}

// 获取电表告警门限
export const devicePowerAlarmThreshold = (params, data) => {
  return apiAxios.get('device/power/alarm/threshold', {
    params: params,
    withMerchantOpenId: true
  })
}

// 设置电表告警门限
export const devicePowerAlarmThresholdSet = (params, data) => {
  return apiAxios.post('device/power/alarm/threshold', data, {
    params: params,
    withMerchantOpenId: true
  })
}

// 获取微信绑定二维码
export const userWxBindQrcode = (params) => {
  return apiAxios.get('user/wxBindQrcode', {
    params: params,
    withMerchantOpenId: true
  })
}

// 新增商户
export const merchantAdd = (params, data) => {
  return apiAxios.post('merchant/add', data, {
    params: params,
    withMerchantOpenId: true
  })
}

// 获取商户label
export const merchantLabel = (params) => {
  return apiAxios.get('merchant/label', {
    params: params
  })
}

// 商户label
export const updateMerchantLabel = (params, data) => {
  return apiAxios.post('merchant/label', data, {
    params: params
  })
}

// 新增消防设备
export const fireDeviceAdd = (params, data) => {
  return apiAxios.post('fireDevice/add', data, {
    params: params,
    withMerchantOpenId: true
  })
}

// 消防设备详情
export const fireDeviceDetail = (params) => {
  return apiAxios.get('fireDevice/detail', {
    params: params,
    withMerchantOpenId: true
  })
}

// 消防设备状态统计
export const fireDeviceCount = (params) => {
  return apiAxios.get('fireDevice/count', {
    params: params,
    withMerchantOpenId: true
  })
}

// 消防设备分类别状态统计
export const fireDeviceCountByType = (params) => {
  return apiAxios.get('fireDevice/countByType', {
    params: params,
    withMerchantOpenId: true
  })
}

// 消防设备按设备汇总统计
export const fireDeviceCountFireDeviceAlarm = (params) => {
  return apiAxios.get('fireDevice/countFireDeviceAlarm', {
    params: params,
    withMerchantOpenId: true
  })
}

// 用户火警列表
export const publicUserFireAlarmList = (params) => {
  return apiAxios.get('publicUser/fireAlarm/list', {
    params: params,
    withMerchantOpenId: true
  })
}

// 人工处理告警
export const publicUserFireAlarmClear = (params, data) => {
  return apiAxios.post('publicUser/fireAlarm/clear', data, {
    params: params,
    withMerchantOpenId: true,
    formData: true
  })
}

// 查询所有的查岗摄像头设备
export const inspectionNewQrCode = (params) => {
  return apiAxios.get('inspection/newQrCode', {
    params: params,
    withMerchantOpenId: true
  })
}

// 查询设备巡检记录
export const inspectionRecordList = (params) => {
  return apiAxios.get('inspection/record/list', {
    params: params,
    withMerchantOpenId: true
  })
}

// 查询设备巡检记录计信息
export const inspectionPointStatistics = (params) => {
  return apiAxios.get('inspection/point/statistics', {
    params: params,
    withMerchantOpenId: true
  })
}

// 查询所有的查岗摄像头设备
export const dutyInspectDeviceList = (params) => {
  return apiAxios.get('dutyInspect/device/list', {
    params: params,
    withMerchantOpenId: true
  })
}

// 查询查岗点
export const dutyInspectPointPage = (params) => {
  return apiAxios.get('dutyInspect/point/page', {
    params: params,
    withMerchantOpenId: true
  })
}

// 查询所有的查岗统计信息
export const dutyInspectDeviceStatistics = (params) => {
  return apiAxios.get('dutyInspect/device/statistics', {
    params: params,
    withMerchantOpenId: true
  })
}

// 查询消防查岗记录
export const dutyInspectRecordPage = (params) => {
  return apiAxios.get('dutyInspect/record/page', {
    params: params,
    withMerchantOpenId: true
  })
}

// 更新消防查岗记录
export const dutyInspectRecordUpdateStatus = (params) => {
  return apiAxios.get('dutyInspect/record/updateStatus', {
    params: params,
    withMerchantOpenId: true
  })
}

// 查询所有的消防通道摄像头设备
// 废弃
export const fpRoadInspectDeviceList = (params) => {
  return apiAxios.get('fpRoadInspect/device/list', {
    params: params,
    withMerchantOpenId: true
  })
}

// 查询消防通道点
export const fpRoadInspectPointPage = (params) => {
  return apiAxios.get('fpRoadInspect/point/page', {
    params: params,
    withMerchantOpenId: true
  })
}

// 查询所有的消防通道统计信息
export const fpRoadInspectDeviceStatistics = (params) => {
  return apiAxios.get('fpRoadInspect/device/statistics', {
    params: params,
    withMerchantOpenId: true
  })
}

// 查询消防通道记录
export const fpRoadInspectRecordPage = (params) => {
  return apiAxios.get('fpRoadInspect/record/page', {
    params: params,
    withMerchantOpenId: true
  })
}

// 更新消防通道记录
export const fpRoadInspectRecordUpdateStatus = (params) => {
  return apiAxios.get('fpRoadInspect/record/updateStatus', {
    params: params,
    withMerchantOpenId: true
  })
}

// 新增应急预案
export const rescuePlanAdd = (params, data) => {
  return apiAxios.post('rescue/plan/add', data, {
    params: params,
    withMerchantOpenId: true
  })
}

// 新增应急资源
export const rescueResourceAdd = (params, data) => {
  return apiAxios.post('rescue/resource/add', data, {
    params: params,
    withMerchantOpenId: true
  })
}

// 编辑应急预案
export const rescuePlanUpdate = (params, data) => {
  return apiAxios.post('rescue/plan/update', data, {
    params: params,
    withMerchantOpenId: true
  })
}

// 编辑应急资源
export const rescueResourceUpdate = (params, data) => {
  return apiAxios.post('rescue/resource/update', data, {
    params: params,
    withMerchantOpenId: true
  })
}

// 查询应急预案
export const rescuePlanList = (params) => {
  return apiAxios.get('rescue/plan/list', {
    params: params,
    withMerchantOpenId: true
  })
}

// 查询应急资源
export const rescueResourceList = (params) => {
  return apiAxios.get('rescue/resource/list', {
    params: params,
    withMerchantOpenId: true
  })
}

// 应急预案上传附件
export const rescuePlanAttachment = (params, data) => {
  return apiAxios.post('rescue/plan/attachment', data, {
    params: params,
    withMerchantOpenId: true
  })
}

// 应急预案删除附件
export const rescuePlanAttachmentDel = (params, data) => {
  return apiAxios.post('rescue/plan/attachment/del', data, {
    params: params,
    withMerchantOpenId: true
  })
}

// 应急资源上传附件
export const rescueResourceAttachment = (params, data) => {
  return apiAxios.post('rescue/resource/attachment', data, {
    params: params,
    withMerchantOpenId: true
  })
}

// 应急资源删除附件
export const rescueResourceAttachmentDel = (params, data) => {
  return apiAxios.post('rescue/resource/attachment/del', data, {
    params: params,
    withMerchantOpenId: true
  })
}

// 新增文档档案
export const documentArchiveAdd = (params, data) => {
  return apiAxios.post('documentArchive/add', data, {
    params: params,
    withMerchantOpenId: true
  })
}

// 编辑文档档案
export const documentArchiveUpdate = (params, data) => {
  return apiAxios.post('documentArchive/update', data, {
    params: params,
    withMerchantOpenId: true
  })
}

// 查询文档档案
export const documentArchiveList = (params) => {
  return apiAxios.get('documentArchive/list', {
    params: params,
    withMerchantOpenId: true
  })
}

// 文档档案上传附件
export const documentArchiveAttachment = (params, data) => {
  return apiAxios.post('documentArchive/attachment', data, {
    params: params,
    withMerchantOpenId: true
  })
}

// 文档档案删除附件
export const documentArchiveAttachmentDel = (params, data) => {
  return apiAxios.post('documentArchive/attachment/del', data, {
    params: params,
    withMerchantOpenId: true
  })
}

// 上传配置信息
export const uploadOssPostSign = (params) => {
  return apiAxios.get('upload/ossPostSign', {
    params: params,
    withMerchantOpenId: true
  })
}

// 查询所有的查岗统计信息
export const deviceFireWaterStatistics = (params) => {
  return apiAxios.get('device/fire/water/statistics', {
    params: params,
    withMerchantOpenId: true
  })
}

// 查询所有的查岗统计信息
export const deviceDashboardStatistics = (params) => {
  return apiAxios.get('device/dashboardStatistics', {
    params: params,
    withMerchantOpenId: true
  })
}

// 查询汇总报告概要
export const reportSummary = (params) => {
  return apiAxios.get('report/summary', {
    params: params,
    withMerchantOpenId: true
  })
}

// 查询汇总报告概要
export const reportSummaryHtml = (params) => {
  return apiAxios.get('report/summary/html', {
    params: params,
    withMerchantOpenId: true
  })
}

// 查询汇总报告概要
export const reportSummaryPdf = (params) => {
  return `${API_URL}report/summary/pdf?start=${params.start}&type=${params.type}&merchantOpenId=${store.merchantInfo.merchantOpenId}&token=${store.token}`
}

// 查询周汇总报告 @Deprecated
export const reportPowerWeek = (params) => {
  return apiAxios.get('report/power/week', {
    params: params,
    withMerchantOpenId: true
  })
}

// 查询月汇总报告 @Deprecated
export const reportPowerMonth = (params) => {
  return apiAxios.get('report/power/month', {
    params: params,
    withMerchantOpenId: true
  })
}

// 智慧用电按周查询告警列表
export const reportPowerWeekAlarm = (params) => {
  return apiAxios.get('report/power/week/alarm', {
    params: params,
    withMerchantOpenId: true
  })
}

// 智慧用电按月查询告警列表
export const reportPowerMonthAlarm = (params) => {
  return apiAxios.get('report/power/month/alarm', {
    params: params,
    withMerchantOpenId: true
  })
}

// 查询组织的打分信息
export const reportCredit = (params) => {
  return apiAxios.get('report/credit', {
    params: params,
    withMerchantOpenId: true
  })
}

// 向组织所有管理员发送消防安全指数通知
export const reportScoreNotify = (params, data) => {
  return apiAxios.post('report/scoreNotify', data, {
    params: params,
    withMerchantOpenId: true
  })
}

// 工单用户列表
export const workOrderAssignUserList = (params) => {
  return apiAxios.get('workOrder/assignUserList', {
    params: params,
    withMerchantOpenId: true
  })
}

// 工单列表
export const workOrderList = (params) => {
  return apiAxios.get('workOrder/list', {
    params: params,
    withMerchantOpenId: true
  })
}

// 工单详情
export const workOrderDetail = (params) => {
  return apiAxios.get('workOrder/detail', {
    params: params,
    withMerchantOpenId: true
  })
}

// 新增工单
export const workOrderAdd = (params, data) => {
  return apiAxios.post('workOrder/add', data, {
    params: params,
    withMerchantOpenId: true
  })
}

// 工单进展
export const workOrderProgress = (params, data) => {
  return apiAxios.post('workOrder/progress', data, {
    params: params,
    withMerchantOpenId: true
  })
}

// 工单关闭
export const workOrderClose = (params, data) => {
  return apiAxios.post('workOrder/close', data, {
    params: params,
    withMerchantOpenId: true
  })
}

// 工单统计
export const workOrderStatistics = (params) => {
  return apiAxios.get('workOrder/statistics', {
    params: params,
    withMerchantOpenId: true
  })
}

// 标签树
export const labelTree = (params) => {
  return apiAxios.get('label/tree', {
    params: params
  })
}

// 获取商户管理地区
export const merchantManageArea = (params) => {
  return apiAxios.get('merchant/manage/area', {
    params: params
  })
}

// 新增商户管理地区
export const merchantManageAreaAdd = (params, data) => {
  return apiAxios.post('merchant/manage/area/add', data, {
    params: params
  })
}

// 删除商户管理地区
export const merchantManageAreaDel = (params, data) => {
  return apiAxios.post('merchant/manage/area/del', data, {
    params: params
  })
}

// 操作日志
export const merchantOperationLog = (params) => {
  return apiAxios.get('merchant/operationLog', {
    params: params
  })
}

// 区块payload查询
export const blockChainPayloadByHash = (params) => {
  return axios.get(`https://explorer.hudsonpower.cn/block/verify/payload/${params.hash}`)
}
