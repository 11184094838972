<template>
  <div style="padding: 15px;">
    <el-tabs class="tabs" type="border-card" tab-position="top" :value="tabSelected" @tab-click="tabSelect">
      <el-tab-pane label="基本信息" name="basicInfo" class="clearfix">
        <el-form ref="userInfoForm" :model="userInfo" label-width="100px" style="width: 400px;">
          <el-form-item label="账户">
            <el-input v-model="userInfo.mobile" disabled></el-input>
          </el-form-item>
          <el-form-item label="姓名">
            <el-input v-model="userInfo.name" disabled></el-input>
          </el-form-item>
          <el-form-item label="微信绑定">
            <img :src="userInfo.avatar" v-if="userInfo.wxOpenId" style="width: 64px; height: 64px;" />
            <el-popover v-else trigger="click" placement="bottom" @show="getWxBindQrcode">
              <div class="text-center" v-loading="!qrcodeUrl">
                <img :src="qrcodeUrl" style="width: 256px; height: 256px;" /><br/>
                <span>微信扫该码关注公众号后，即可绑定</span><br/>
                <span class="text-info">二维码有效期: 3分钟</span>
              </div>
              <el-button slot="reference" type="primary" icon="fa fa-qrcode fa-fw"> 微信扫码绑定</el-button>
            </el-popover>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { userInfo, userWxBindQrcode } from '../api/api'

export default {
  data () {
    return {
      tabSelected: 'basicInfo',
      userInfo: {},
      qrcodeUrl: ''
    }
  },
  methods: {
    load () {
      this.loadBasicInfo()
    },
    tabSelect (tab) {
      if (tab.name === 'basicInfo') {
        this.loadBasicInfo()
      }
    },
    loadBasicInfo () {
      userInfo().then((data) => {
        this.userInfo = data.result
      })
    },
    getWxBindQrcode () {
      userWxBindQrcode({}).then((data) => {
        this.qrcodeUrl = data.result.qrcodeUrl
      })
    }
  },
  created () {
    this.load()
  }
}
</script>
<style lang="less" scoped>
.tabs {
  min-height: 500px;
}
</style>
