<template>
  <div style="padding: 15px;">
    <!-- <h3 class="text-primary">电表性能报告查询</h3> -->
    <div class="query-bar">
      <el-select v-model="query.deviceOpenId" @change="deviceSelected" filterable placeholder="请选择设备" style="width: 260px;">
        <el-option v-for="item in deviceList" :key="item.openId" :label="item.name" :value="item.openId"></el-option>
      </el-select>

      <el-date-picker v-model="query.dateRange" type="daterange" value-format="timestamp" align="center" :clearable="false" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="queryDatePickerOptions">
      </el-date-picker>
      <el-button type="primary" @click.prevent="load">
        <i class="fa fa-search"></i>
      </el-button>
    </div>
    <div>
      <chart :options="chartOptions" ref="barChart" auto-resize style="width: 100%; height: 500px;"></chart>
      <div class="text-center" style="padding: 15px 0;">
        <el-radio-group v-model="chartMetics" size="medium" @change="chartMeticsChange">
          <el-radio-button :label="1">温升(℃)</el-radio-button>
          <el-radio-button :label="2">功率(kw)</el-radio-button>
          <el-radio-button :label="3">温升/功率(℃/kw)</el-radio-button>
        </el-radio-group>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { deviceList, devicePerformance } from '@/api/api'

export default {
  data () {
    this.chartSettings = {}
    return {
      query: {
        deviceOpenId: '',
        dateRange: [new Date().getTime() - 3600 * 1000 * 24, new Date().getTime() + 3600 * 1000 * 24]
      },
      queryDatePickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick (picker) {
            const today = moment({ hour: 0 })
            const tomorrow = moment({ hour: 0 }).add(1, 'days')
            const start = today.toDate()
            const end = tomorrow.toDate()
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一天',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近两天',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 2)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      deviceList: [],
      chartOptions: {},
      chartMetics: 1
    }
  },
  methods: {
    load () {
      let params = {
        deviceOpenId: this.query.deviceOpenId,
        startTime: this.query.dateRange[0],
        endTime: this.query.dateRange[1]
      }
      this.chartMetics = 1
      let barChart = this.$refs.barChart
      barChart.showLoading()
      devicePerformance(params).then((data) => {
        if (data.result && data.result.length > 0) {
          // temperature rise
          let minTemperatureA = data.result[0].temperatureA
          let minTemperatureB = data.result[0].temperatureB
          let minTemperatureC = data.result[0].temperatureC
          let minTemperatureN = data.result[0].temperatureN
          for (let i = 0; i < data.result.length; i++) {
            if (minTemperatureA > data.result[i].temperatureA) minTemperatureA = data.result[i].temperatureA
            if (minTemperatureB > data.result[i].temperatureB) minTemperatureB = data.result[i].temperatureB
            if (minTemperatureC > data.result[i].temperatureC) minTemperatureC = data.result[i].temperatureC
            if (minTemperatureN > data.result[i].temperatureN) minTemperatureN = data.result[i].temperatureN
          }
          let fixValue = function (v) {
            if (v === Infinity || isNaN(v)) {
              return 0.01
            }
            return v.toFixed(2)
          }
          for (let i = 0; i < data.result.length; i++) {
            // 温升
            data.result[i].temperatureRiseA = (data.result[i].temperatureA - minTemperatureA).toFixed(2)
            data.result[i].temperatureRiseB = (data.result[i].temperatureB - minTemperatureB).toFixed(2)
            data.result[i].temperatureRiseC = (data.result[i].temperatureC - minTemperatureC).toFixed(2)
            data.result[i].temperatureRiseN = (data.result[i].temperatureN - minTemperatureN).toFixed(2)
            // 温升/功率
            data.result[i].temperatureRiseAPerKw = fixValue(data.result[i].temperatureRiseA / data.result[i].currentA / data.result[i].voltageA * 1000)
            data.result[i].temperatureRiseBPerKw = fixValue(data.result[i].temperatureRiseB / data.result[i].currentB / data.result[i].voltageB * 1000)
            data.result[i].temperatureRiseCPerKw = fixValue(data.result[i].temperatureRiseC / data.result[i].currentC / data.result[i].voltageC * 1000)
            data.result[i].powerA = (data.result[i].currentA * data.result[i].voltageA / 1000).toFixed(2)
            data.result[i].powerB = (data.result[i].currentB * data.result[i].voltageB / 1000).toFixed(2)
            data.result[i].powerC = (data.result[i].currentC * data.result[i].voltageC / 1000).toFixed(2)
          }

          this.chartOptions = {
            legend: {
              textStyle: {
                fontSize: 18
              }
            },
            color: ['#005eaa', '#2b821d', '#25C6FC', '#c23531', '#91c7ae', '#749f83', '#ca8622', '#bda29a', '#6e7074', '#546570', '#c4ccd3'],
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                label: {
                  show: true,
                  formatter: (param) => {
                    let date = new Date(param.value)
                    let str = this.$filter['date'](date, 'YYYY-MM-DD HH:mm')
                    return str
                  }
                }
              }
            },
            dataset: {
              source: data.result
            },
            xAxis: { type: 'time' },
            yAxis: { scale: true },
            series: this.getChartSeries(['temperatureRiseA', 'temperatureRiseB', 'temperatureRiseC', 'temperatureRiseN'])
          }
        } else {
          this.$message.warning('该时间段暂无数据！')
        }
        barChart.hideLoading()
      })
    },
    getChartSeries (list) {
      let labelMap = {
        'createTime': '时间',
        'temperatureRiseA': '温升A',
        'temperatureRiseB': '温升B',
        'temperatureRiseC': '温升C',
        'temperatureRiseN': '温升N',
        'temperatureRiseAPerKw': '温升A/功率',
        'temperatureRiseBPerKw': '温升B/功率',
        'temperatureRiseCPerKw': '温升C/功率',
        'powerA': '功率A',
        'powerB': '功率B',
        'powerC': '功率C'
      }
      let serisesArray = []
      for (let seriesName of list) {
        if (typeof seriesName === 'string') {
          serisesArray.push({
            name: labelMap[seriesName],
            type: 'line',
            encode: {
              x: 'createTime',
              y: seriesName
            },
            showSymbol: true,
            symbol: 'circle',
            symbolSize: 5,
            lineStyle: {
              width: 0
            }
          })
        }
      }
      return serisesArray
    },
    chartMeticsChange () {
      if (this.chartMetics === 1) {
        this.chartOptions.series = this.getChartSeries(['temperatureRiseA', 'temperatureRiseB', 'temperatureRiseC', 'temperatureRiseN'])
        this.chartOptions.yAxis = {
          type: 'value',
          scale: true
        }
      } else if (this.chartMetics === 2) {
        this.chartOptions.series = this.getChartSeries(['powerA', 'powerB', 'powerC'])
        this.chartOptions.yAxis = {
          type: 'value',
          scale: true
        }
      } else if (this.chartMetics === 3) {
        this.chartOptions.series = this.getChartSeries(['temperatureRiseAPerKw', 'temperatureRiseBPerKw', 'temperatureRiseCPerKw'])
        this.chartOptions.yAxis = {
          type: 'log',
          logBase: 10,
          min: 0.01
        }
      }
    },
    deviceSelected () {
      this.$router.push({ query: { deviceOpenId: this.query.deviceOpenId } })
      localStorage.setItem('hudson.power.lastSelectDevice', this.query.deviceOpenId)
    }
  },
  created () {
    // init device list
    deviceList({ type: 0 }).then((data) => {
      this.deviceList = data.result
      if (this.$route.query.deviceOpenId) {
        this.query.deviceOpenId = this.$route.query.deviceOpenId
      }
      if (!this.query.deviceOpenId) {
        let lastSelecteDevice = localStorage.getItem('hudson.power.lastSelectDevice')
        if (lastSelecteDevice) {
          for (let item of this.deviceList) {
            if (lastSelecteDevice === item.openId) this.query.deviceOpenId = lastSelecteDevice
          }
        }
      }
      if (!this.query.deviceOpenId && this.deviceList[0]) this.query.deviceOpenId = this.deviceList[0].openId

      this.load()
    })
  }
}
</script>
<style lang="scss" scoped>
.el-radio-group /deep/ .el-radio-button__inner {
  font-size: 18px;
}
</style>
