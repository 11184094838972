<template>
  <div style="padding: 15px;">
    <el-row>
      <el-col :span="24" class="query-bar">
        <el-form ref="form" :model="queryform" :inline="true" label-width="100px">

          <el-form-item label="关键词">
            <el-input v-model="query.keyword" placeholder="名称"></el-input>
          </el-form-item>
          <el-form-item label="地区">
            <el-popover
              placement="bottom-start"
              title="选择区域"
              width="360"
              trigger="hover">
              <div style="min-width: 240px; background-color: #fff; border-radius: 4px; border: 1px solid #dcdfe6; padding: 0 15px;" slot="reference">
                <template v-if="query.areaList && query.areaList.length"><span class="text-primary">{{ query.areaList.join(' ') }}</span> <el-button type="text" icon="fa fa-remove" @click="query.areaList=[]"></el-button></template>
                <template v-else><span class="text-info">请选择</span></template>
              </div>
              <el-row>
                <el-col :span="6">
                  <div><i class="fa fa-history"></i> 历史记录</div>
                  <div v-if="areaLastSelectList.length == 0" class="text-info">暂无</div>
                  <div v-for="item in areaLastSelectList">
                    <el-button type="text" @click="query.areaList = item.split('|')">{{item.split('|').reverse()[0]}}</el-button>
                  </div>
                </el-col>
                <el-col :span="18">
                  <area-editor :selected.sync="query.areaList" style="height: 360px; overflow: auto; margin-right: 10px;"></area-editor>
                </el-col>
              </el-row>
            </el-popover>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" native-type="submit" @click.prevent="load"><i class="fa fa-search fa-fw"></i></el-button>
          </el-form-item>
          <el-button type="success" icon="fa fa-user-plus fa-fw" style="float:right;" @click="addMerchant()"> 新增组织</el-button>
        </el-form>

      </el-col>
      
      <el-col :span="24" class="main-content">
        <el-table
          :data="merchantList"
          v-loading="loading"
          stripe
          border
          style="width: 100%">
          <el-table-column
            type="index"
            label=""
            width="50">
          </el-table-column>
          <el-table-column
            prop="name"
            label="名称"
            width="300">
          </el-table-column>
          <el-table-column
            prop="statusDesc"
            label="状态"
            width="80">
          </el-table-column>
          <el-table-column
            prop="areaLabelList"
            label="归属地区">
            <template slot-scope="scope">
              <span v-if="scope.row.areaLabelList">{{ scope.row.areaLabelList }}</span>
              <el-button type="text" @click="setArea(scope.row)"><i class="fa fa-edit"></i></el-button>
            </template>
          </el-table-column>
          <el-table-column
            label="管理地区">
            <template slot-scope="scope">
              <el-tag v-for="item in scope.row.manageAreaList" :key="item.id" 
                @close="manageAreaDel(scope.row, item)" closable
                size="small" style="margin-bottom: 5px;"
                >{{item.value}}</el-tag>
              <el-button type="text" @click="manageAreaAdd(scope.row)"> <i class="fa fa-plus fa-fw"></i> </el-button>
            </template>
          </el-table-column>
          <el-table-column
            label="Logo"
            width="100">
            <template slot-scope="scope">
              <div><img v-if="scope.row.logo" :src="scope.row.logo" with="64" height="64"></img></div>
              <div><el-button type="text" size="small" icon="fa fa-upload" @click="uploadLogo(scope.row)"> Logo</el-button></div>
            </template>
          </el-table-column>
          <el-table-column
            label="短名称"
            width="100">
            <template slot-scope="scope">
              {{scope.row.shortName}}
              <el-button type="text" size="small" icon="fa fa-edit" @click="editShortName(scope.row)"></el-button>
            </template>
          </el-table-column>
          <!-- <el-table-column
            label="操作"
            width="200">
            <template slot-scope="scope">
          </template>
          </el-table-column> -->
        </el-table>
      </el-col>
      <el-col :span="24" class="tool-bar">
        <el-pagination @current-change="load" :current-page.sync="pageData.pageNo" :page-size="pageData.pageSize" layout="total, prev, pager, next" :total="pageData.total" style="float: right;">
        </el-pagination>
      </el-col>
    </el-row>


    <el-dialog :visible.sync="areaEditorVisible" width="40%">
      <div slot="title">选择：<span class="text-primary">{{ areaEditorSelected.join(' ') }}</span></div>
      <div style="text-align: center; height: 480px; overflow-y: auto;" v-if="areaEditorVisible">
        <area-editor :selected.sync="areaEditorSelected" ></area-editor>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="areaEditorVisible = false">取 消</el-button>
        <el-button type="primary" @click="areaEditSubmit" :loading="submitLoading">确 定</el-button>
      </div>
    </el-dialog>


    <el-dialog :visible.sync="manageAreaEditorVisible" width="40%">
      <div slot="title">选择：<span class="text-primary">{{ manageAreaSelectedValue.join(' ') }}</span></div>
      <div style="text-align: center; height: 480px; overflow-y: auto;" v-if="manageAreaEditorVisible">
        <area-editor :selected.sync="manageAreaSelectedValue" :selected-model.sync="manageAreaSelected" ></area-editor>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="manageAreaEditorVisible = false">取 消</el-button>
        <el-button type="primary" @click="manageAreaAddSubmit" :loading="submitLoading">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="uploadDialogVisible" title="上传文本">
      <el-upload
        v-if="uploadDialogVisible && uploadConfig.host"
        class="upload"
        accept="image/jpeg,image/png,image/gif"
        :action="uploadConfig.host"
        :limit="1"
        :data="uploadFormData"
        :before-upload="beforeUpload"
        :on-success="uploadSuccess"
        >
        <el-button size="small" type="primary">点击上传</el-button>
        <div slot="tip" class="el-upload__tip">只能上传图片(jpg,jpeg,png,gif)文件</div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="uploadDialogVisible = false">完成</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { merchantPage, merchantLabel, updateMerchantLabel, merchantManageArea, merchantManageAreaDel, merchantManageAreaAdd, uploadOssPostSign, merchantSetInfo } from '../api/api'
import AreaEditor from '@/components/AreaEditor'

export default {
  components: {
    AreaEditor
  },
  data () {
    return {
      queryform: {},
      query: {
        keyword: '',
        areaList: []
      },
      pageData: {
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      merchantList: [],
      currentMerchant: {},
      loading: true,
      areaEditorVisible: false,
      areaEditorSelected: [],
      submitLoading: false,
      areaLastSelectList: [],
      manageAreaEditorVisible: false,
      manageAreaSelected: [],
      manageAreaSelectedValue: [],
      uploadDialogVisible: false,
      uploadConfig: {},
      uploadingItem: {},
      uploadFormData: {}
    }
  },
  computed: {
  },
  methods: {
    load () {
      this.loading = true
      let params = {
        keyword: this.query.keyword,
        areaValueList: this.query.areaList.join('|'),
        pageNo: this.pageData.pageNo - 1,
        pageSize: this.pageData.pageSize
      }
      if (params.areaValueList) {
        let exsting = false
        for (let item of this.areaLastSelectList) {
          if (params.areaValueList === item) {
            exsting = true
          }
        }
        if (!exsting) {
          if (this.areaLastSelectList.length >= 5) this.areaLastSelectList.shift()
          this.areaLastSelectList.push(params.areaValueList)
          localStorage.setItem('hudson.area.lastSelectList', this.areaLastSelectList.join(','))
        }
      }
      merchantPage(params).then(data => {
        this.merchantList = data.result.content
        this.pageData.total = data.result.totalElements
        for (let merchant of this.merchantList) {
          this.$set(merchant, 'areaLabelList', '...')
          let params = {merchantOpenId: merchant.openId, key: 'area'}
          merchantLabel(params).then((data) => {
            let values = []
            for (let j in data.result) {
              values.push(data.result[j].value)
            }
            // console.log(values)
            // console.log(merchant)
            merchant.areaLabelList = values.join(' ')
          })
          this.$set(merchant, 'manageAreaList', [])
          let params2 = {merchantOpenId: merchant.openId}
          merchantManageArea(params2).then((data) => {
            merchant.manageAreaList = data.result
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },
    addMerchant () {
      this.$router.push({path: '/merchant/add'})
    },
    setArea (merchant) {
      this.currentMerchant = merchant
      this.areaEditorVisible = true
    },
    areaEditSubmit () {
      if (!this.currentMerchant.openId) return
      if (this.areaEditorSelected.length === 0) {
        this.$message.error('请选择至少一个区域')
        return
      }
      if (this.areaEditorSelected.length > 8) {
        this.$message.error('最多选择8个区域')
        return
      }

      // 提交
      let params = {
        merchantOpenId: this.currentMerchant.openId,
        key: 'area',
        valueList: this.areaEditorSelected.join('|')
      }

      this.submitLoading = true
      updateMerchantLabel(params, {}).then((data) => {
        this.areaEditorVisible = false
        this.load()
      }).finally(() => {
        this.submitLoading = false
      })
    },
    manageAreaDel (merchant, label) {
      this.$confirm(`确认删除？`, '确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        let param = {merchantOpenId: merchant.openId, areaId: label.id}
        merchantManageAreaDel(param).then((data) => {
          this.load()
        })
      })
    },
    manageAreaAdd (merchant) {
      this.currentMerchant = merchant
      this.manageAreaEditorVisible = true
    },
    manageAreaAddSubmit () {
      if (!this.currentMerchant.openId) return
      if (this.manageAreaSelected.length === 0) {
        this.$message.error('请选择至少一个区域')
        return
      }
      if (this.manageAreaSelected.length > 10) {
        this.$message.error('最多选择10个区域')
        return
      }

      // 提交
      let params = {
        merchantOpenId: this.currentMerchant.openId,
        areaId: this.manageAreaSelected[this.manageAreaSelected.length - 1].id
      }

      this.submitLoading = true
      merchantManageAreaAdd(params, {}).then((data) => {
        this.manageAreaEditorVisible = false
        this.load()
      }).finally(() => {
        this.submitLoading = false
      })
    },
    uploadLogo (item) {
      if (!item.openId) return
      this.uploadingItem = item
      uploadOssPostSign().then((data) => {
        this.uploadConfig = data.result
        this.uploadDialogVisible = true
      })
    },
    beforeUpload (file) {
      return new Promise((resolve, reject) => {
        this.uploadFormData = {
          OSSAccessKeyId: this.uploadConfig.accessid,
          policy: this.uploadConfig.policy,
          signature: this.uploadConfig.signature,
          key: this.uploadConfig.key,
          success_action_status: '200',
          'x-oss-meta-user': this.uploadConfig.user,
          'x-oss-meta-merchant': this.uploadConfig.merchant,
          'Content-Disposition': 'filename=' + file.name
        }
        setTimeout(() => {
          resolve()
        }, 100)
      })
    },
    uploadSuccess (res, file, fileList) {
      let param = {
        openId: this.uploadingItem.openId,
        logo: this.uploadConfig.host + this.uploadConfig.key
      }
      merchantSetInfo(param, {}).then((data) => {
        this.load()
      })
    },
    editShortName (item) {
      this.$prompt('请输入短名称', '提示', {
        inputPattern: /^.{2,6}$/,
        inputErrorMessage: '格式不正确，长度应为2-6'
      }).then(({ value }) => {
        let param = {
          openId: item.openId,
          shortName: value
        }
        merchantSetInfo(param, {}).then(() => {
          this.load()
        })
      })
    }
  },
  created () {
    let areaLastSelectList = localStorage.getItem('hudson.area.lastSelectList')
    this.areaLastSelectList = areaLastSelectList ? areaLastSelectList.split(',') : []
    this.load()
  }
}
</script>
<style lang="less" scoped>


</style>
