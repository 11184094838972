<template>
  <div style="width: 100%; height: 100%; min-width: 1020px; min-height: 700px; position: absolute;">
    <div class="back">
      <a href="javascript:history.go(-1)">
        <i class="fa fa-fw fa-chevron-left fa-2x"></i>
      </a>
    </div>
    <div class="title">
      <span>{{ deviceInfo.name }}</span>
    </div>
    
    <div class="box-container">
      <div v-if="!videoSetting.channelList || videoSetting.channelList.length === 0" style="flex: 1;">
        <p class="text-danger text-center">无视频</p>
      </div>
      <div class="box" :class="{'box-3-3': videoSetting.channelList.length > 4}" v-for="channel in videoSetting.channelList">
        <!-- <div class="hd">
          <h4>视频 - {{ channel.id }}</span></h4>
        </div> -->
        <div class="bd" style="position: relative;">
          <video-channnel-player :channel="channel" style="position: absolute; width: 100%; height: 100%;" ></video-channnel-player>
        </div>
        <div class="ft">
          <div class="text-center text-info">
            <el-popover
              placement="top"
              title="扫码可在手机上查看"
              trigger="hover"
              content="">
              <img style="margin: 0 auto;" width="200" height="200" :src="qrcode(channel)"></img>
              <el-button slot="reference" type="text"><i class="fa fa-qrcode"></i> 手机上查看</el-button>
            </el-popover>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import { deviceDetail } from '@/api/api'
import videoChannnelPlayer from '@/components/VideoChannelPlayer'
// import EZUIPlayer from '@/lib/ezuikit.js'
// import Hls from 'hls.js'

export default {
  components: {
    videoChannnelPlayer
  },
  data () {
    return {
      deviceInfo: {},
      videoSetting: {}
    }
  },
  methods: {
    load () {
      let param = { deviceOpenId: this.$route.query.deviceOpenId }
      deviceDetail(param).then((data) => {
        this.deviceInfo = data.result
        this.videoSetting = JSON.parse(this.deviceInfo.videoSetting)
      })
    },
    qrcode (channel) {
      return `https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${encodeURIComponent(channel.hls)}`
    }
  },
  created () {
    if (!this.$route.query.deviceOpenId) {
      this.$alert('参数错误：deviceOpenId不能为空')
    } else {
      this.load()
    }
  }
}
</script>
<style lang="scss" scoped>
.box-container {
  display: flex;
  position: absolute;
  top: 100px;
  bottom: 0;
  width: 100%;
  flex-wrap: wrap;
}

.box {
  height: 50%;
  width: 50%;
  box-sizing: border-box;
  padding: 5px;
  display: flex;
  flex-direction: column;
  .hd {
    background-color: #333;
    color: #25c6fc;
    height: 40px;
    overflow: hidden;
    a {
      color: #25c6fc;
    }
    h4 {
      font-size: 16px;
      margin: 0;
      padding: 10px 5px;
      text-align: center;
    }
  }
  .bd {
    background-color: rgba(0, 0, 0, 0.8);
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .ft {
    background-color: #333;
  }
}

.box-3-3 {
  height: 33.33%;
  width: 33.33%;
}

.back {
  position: absolute;
  left: 20px;
  top: 20px;
  width: 60px;
  height: 60px;
  text-align: center;
  // background-color: rgba(0, 0, 0, 0.7);
  a {
    color: #999;
    i {
      margin-top: 15px;
    }
  }
  a:hover {
    color: #000;
  }
}
.title {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  // width: 580px;
  padding: 20px 10px;
  text-align: center;
  // background-color: rgba(0, 0, 0, 0.7);
  span {
    line-height: 1;
    font-size: 32px;
    color: #25c6fc;
    font-weight: 800;
  }
}
</style>

