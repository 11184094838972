<template>
  <div class="data-view">
    <div style="width: 100%; height: 100%; overflow: hidden; background: #000000" v-resize:debounce="onResize">
      <div style="width: 100%; height: 100%; transform-origin: left top; display: flex; flex-direction: column;" :style="{transform: scaleStyle}">
        <dv-border-box-1 class="main-container">
          <dv-border-box-9 class="body-column" style="width: 25%;">
            <Left-Chart-1 style="flex: 1;" />
            <Left-Chart-2 style="flex: 1;" />
            <Left-Chart-3 style="flex: 1;" />
          </dv-border-box-9>
          <dv-border-box-8 class="body-column" style="width: 50%;">
            <Mid-Chart-1 style="height: 200px" />
            <Mid-Chart-2 style="flex: 3;" />
            <!--Mid-Chart-3 style="flex: 1;" /-->
          </dv-border-box-8>
          <dv-border-box-9 class="body-column" style="width: 25%;">
            <Right-Chart-1 style="flex: 2;" />
            <Right-Chart-2 style="flex: 2;" />
          </dv-border-box-9>
        </dv-border-box-1>
      </div>
    </div>
  </div>
</template>

<script>

import LeftChart1 from './LeftChart1'
import LeftChart2 from './LeftChart2'
import LeftChart3 from './LeftChart3'

import MidChart1 from './MidChart1'
import MidChart2 from './MidChart2'
// import MidChart3 from './MidChart3'

import RightChart1 from './RightChart1'
import RightChart2 from './RightChart2'

export default {
  name: 'DataView',
  components: {
    LeftChart1,
    LeftChart2,
    LeftChart3,
    MidChart1,
    MidChart2,
    // MidChart3,
    RightChart1,
    RightChart2
  },
  data () {
    return {
      scaleStyle: ''
    }
  },
  methods: {
    onResize (el) {
      let scaleX = el.clientWidth / 1920
      let scaleY = el.clientHeight / 1080
      scaleX = scaleX < 0.5 ? 0.5 : scaleX
      scaleY = scaleY < 0.5 ? 0.5 : scaleY
      scaleX = scaleX > 2 ? 2 : scaleX
      scaleY = scaleY > 2 ? 2 : scaleY

      // 比例调节
      if (scaleY / scaleX > 1.25) scaleY = scaleX * 1.25

      this.scaleStyle = `scale(${scaleX}, ${scaleY})`
    },
  }
}
</script>

<style lang="scss" scoped>
.data-view {
  width: 100%;
  height: 100%;
  background-color: #030409;
  color: #fff;

  .full-screen-container {
    // background-image: url('./img/bg.png');
    background-size: 100% 100%;
    box-shadow: 0 0 3px blue;
    display: flex;
    flex-direction: column;
  }

  .main-container {
    height: 100%;
    background-color: #08153f;
    background-image: url('../../assets/images/bg.png');
    /deep/ .border-box-content {
      padding: 20px;
      box-sizing: border-box;
      display: flex;
    }
    // box-shadow: 0 0 10px 0 $--color-primary inset;
  }

  .body-column {
    width: 33%;
    box-sizing: border-box;

    /deep/ .border-box-content {
      flex-direction: column;
    }
  }
  /deep/.item {
    flex: 1;
  }

}

</style>
