<template>
  <div style="padding: 15px;">
    <!-- <h3 class="text-primary">电表性能报告查询</h3> -->
    <div class="query-bar">
      <el-select v-model="deviceOpenId" filterable placeholder="请选择设备" style="width: 260px;">
        <el-option v-for="item in deviceList" :key="item.openId" :label="item.name" :value="item.openId"></el-option>
      </el-select>
    </div>
    <div style="width: 600px; margin: 100px auto 0 auto;">
          <power-switch-pannel :device-open-id="deviceOpenId"></power-switch-pannel>
    </div>
  </div>
</template>

<script>
import powerSwitchPannel from '@/components/PowerSwitchPannel'
import { deviceList } from '@/api/api'

export default {
  components: {
    powerSwitchPannel
  },
  data () {
    return {
      deviceList: [],
      deviceOpenId: ''
    }
  },
  methods: {
    load () {
    }
  },
  created () {
    // init device list
    deviceList({type: 0}).then((data) => {
      this.deviceList = data.result
      if (this.$route.query.deviceOpenId) {
        this.deviceOpenId = this.$route.query.deviceOpenId
      }
      if (!this.deviceOpenId) {
        let lastSelecteDevice = localStorage.getItem('hudson.power.lastSelectDevice')
        if (lastSelecteDevice) {
          for (let item of this.deviceList) {
            if (lastSelecteDevice === item.openId) this.deviceOpenId = lastSelecteDevice
          }
        }
      }
      if (!this.deviceOpenId && this.deviceList[0]) this.deviceOpenId = this.deviceList[0].openId

      this.load()
    })
  }
}
</script>
