<template>
  <div class="box">
    <div class="display-board">
      <div class="item-row">
        <div class="card">
          <div class="header">本月用电增加</div>
          <div class="content">2000度</div>
        </div>
        <div class="card">
          <div class="header">用电比同期增加</div>
          <div class="content">-10%</div>
        </div>
      </div>
      <div class="item-row">
        <div class="card">
          <div class="header">占地（亩）</div>
          <div class="content">132,867</div>
        </div>
        <div class="card">
          <div class="header">能耗（吨标煤）</div>
          <div class="content">23,498,240</div>
        </div>
      </div>
      <div class="item-row">
        <div class="card">
          <div class="header">用电（万/千瓦时）</div>
          <div class="content">2,307,075</div>
        </div>
        <div class="card">
          <div class="header">排污（当量）</div>
          <div class="content">38,779,940</div>
        </div>
      </div>
    </div>
    <dv-decoration-2 style="height:10px;" />
  </div>
</template>

<script>
// import ecStat from 'echarts-stat'
/* eslint-disable */
export default {
  name: 'LeftChart1',
  data () {
    return {}
  },
  methods: {
    load() {
    },
  },
  mounted() {
    this.load()
  }
}
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 0;
  flex-direction: column;

  .hd {
    text-align: center;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .bd {
    flex:1;
    height: 50px;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-indent: 20px;

    span {
      color: #096dd9;
      font-weight: bold;
      font-size: 35px;
      margin-left: 20px;
    }
  }

  .chart {
    flex: 1;
  }
}

ul {
  padding: 0 1em;
  list-style: none;
  li {
    font-size: 1em;
    padding: 3px 0;
    font-weight: bold;
    a {
      color: #fff;
    }
    .label {
      display: inline-block;
      margin-right: 10px;
      color:#25c6fc;
    }
  }
}

.display-board {
    display: flex;
    flex-direction: column;
    height: 100%;

    .item-row {
        flex: 1;
        display: flex;
        flex-direction: row;
        height: 100%;

        .card {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: #1a53ff;
            padding: 10px;
            border-radius: 20px;
            color: white;
            margin: 10px;

            .header {
                font-size: 15px;
                font-weight: bold;
            }

            .content {
                font-size: 25px;
                font-weight: bold;
            }
        }
    }
}
</style>
