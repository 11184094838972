<template>
  <div class="box">
    <div class="hd">用电分布统计</div>
    <div class="bd">
      <div class="box-progression-stats">
        <div class="content">
          <div id="genderPercent" style="width:100%; height:100%"/>
          <!--div class="item"><span style="color: white; font-size: 18px; font-weight: bold">男性</span></div>
          <div class="item"><el-progress :text-inside="true" :stroke-width="24" :percentage="100" status="success"></el-progress></div>
          <div class="item"><span style="color: white; font-size: 18px; font-weight: bold">女性</span></div>
          <div class="item"><el-progress :text-inside="true" :stroke-width="20" :percentage="50" status="exception"></el-progress></div-->
        </div>
        <div class="widget">
          <div id="agePercent" style="width:100%; height:100%"/>
        </div>
      </div>
    </div>
    <dv-decoration-2 style="height:10px;" />
  </div>
</template>

<script>
/* eslint-disable */
import * as echarts from 'echarts'
export default {
  name: 'LeftChart2',
  data () {
    return {
    }
  },
  methods: {
    load() {
      this.drawGenderChart()
      this.drawAgeChart()
    },
    drawGenderChart() {
      var myChart = echarts.init(document.getElementById("genderPercent"), 'dark');
      var option
      option = {
        title: {
          text: '高用电企业',
          left: 'center'
        },
        backgroundColor:'',
        grid:{
          x:15,
          y:35,
          x2:5,
          y2:5,
          borderWidth:1
        },
        xAxis: {
          type: 'value' ,
        },
        yAxis: {
          type: 'category',
          data: ['企业一', '企业二', '企业三', '企业四', '企业五'],
          axisLine:{
            show: false
          },
          axisLabel: {
            show: false
          }
        },
        series: [{
            data: [600, 700, 800, 900, 1200],
            type: 'bar',
            label: {
              show: true,
              position: 'insideRight',
              formatter: '{b}: {c}度',
              color: "#fff",
            },
            barMaxWidth: 30,
            showBackground: true,
            backgroundStyle: {
                color: 'rgba(180, 180, 180, 0.2)'
            } 
        }]
      };
      // myChart.setOption(option);
      option && myChart.setOption(option);
    },
    drawAgeChart () {
      var myChart = echarts.init(document.getElementById("agePercent"), 'dark');
      var option
      option = {
        backgroundColor:'',
        title: {
            text: '各部门用电情况',
            left: 'center'
        },
        tooltip: {
            trigger: 'item'
        },
        series: [
            {
                name: '各年龄常住人员',
                type: 'pie',
                radius: '65%',
                data: [
                    {value: 104, name: '居民区'},
                    {value: 735, name: '制鞋区'},
                    {value: 580, name: '科技园'},
                    {value: 484, name: '政府部门'},
                ],
                label:{
                  show: true,
                  position: 'inside' 
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
      };
      // myChart.setOption(option);
      option && myChart.setOption(option);
    }
  },
  mounted() {
    this.load()
  }
}
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 0;
  flex-direction: column;

  .hd {
    text-align: center;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .bd {
    flex:1;
    height: 50px;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-indent: 20px;

    span {
      color: #096dd9;
      font-weight: bold;
      font-size: 35px;
      margin-left: 20px;
    }
  }

  .chart {
    flex: 1;
  }
}

ul {
  padding: 0 1em;
  list-style: none;
  li {
    font-size: 1em;
    padding: 3px 0;
    font-weight: bold;
    a {
      color: #fff;
    }
    .label {
      display: inline-block;
      margin-right: 10px;
      color:#25c6fc;
    }
  }
}

.box-progression-stats {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;

  /deep/ .content {
    display: flex;
    flex-direction: column;
    
    justify-content: center;
    align-items: center;
    flex: 3;
    .item {
      width: 100%
    }
  }

  .widget {
    flex: 2
  }
}
</style>
