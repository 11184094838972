<template>
  <div class="containner">
    <canvas ref="canvas" style="width: 100%; height: 100%;"></canvas>
  </div>
</template>
<script>
import qrcode from 'qrcode'
export default {
  name: 'qrcode',
  props: {
    body: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
    }
  },
  watch: {
    body () {
      this.load()
    }
  },
  methods: {
    load () {
      if (!this.body) {
        return
      }
      qrcode.toCanvas(this.$refs['canvas'], this.body, {errorCorrectionLevel: 'H', width: 250, margin: 2})
    }
  },
  mounted () {
    this.load()
  }
}
</script>
<style lang="scss" scoped>
.containner {
  min-width: 128px;
  min-height: 128px;
}
</style>

