<template>
  <div class="box">
    <div class="hd">每月用电统计</div>
    <div class="bd">
      <div id="access" style="width:100%; height:100%"/>
    </div>
    <dv-decoration-2 style="height:10px;" />
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'LeftChart3',
  data () {
    return {
    }
  },
  methods: {
    load() {
      this.drawAccess()
    },
    drawAccess(){
      var myChart = echarts.init(document.getElementById("access"), 'dark');
      var option
      option = {
          backgroundColor:'',
          xAxis: {
              type: 'category',
              boundaryGap: false
          },
          yAxis: {
              type: 'value',
              boundaryGap: [0, '30%']
          },
          visualMap: {
              type: 'piecewise',
              show: false,
              dimension: 0,
              seriesIndex: 0,
              pieces: [{
                  gt: 1,
                  lt: 3,
                  color: 'rgba(0, 0, 180, 0.4)'
              }, {
                  gt: 5,
                  lt: 7,
                  color: 'rgba(0, 0, 180, 0.4)'
              }]
          },
          series: [
              {
                  type: 'line',
                  smooth: 0.6,
                  symbol: 'none',
                  lineStyle: {
                      color: '#5470C6',
                      width: 5
                  },
                  markLine: {
                      symbol: ['none', 'none'],
                      label: {show: false},
                      data: [
                          {xAxis: 1},
                          {xAxis: 3},
                          {xAxis: 5},
                          {xAxis: 7}
                      ]
                  },
                  areaStyle: {},
                  data: [
                      ['2019-10-10', 200],
                      ['2019-10-11', 560],
                      ['2019-10-12', 750],
                      ['2019-10-13', 580],
                      ['2019-10-14', 250],
                      ['2019-10-15', 300],
                      ['2019-10-16', 450],
                      ['2019-10-17', 300],
                      ['2019-10-18', 100]
                  ]
              }
          ]
      };
      // myChart.setOption(option);
      option && myChart.setOption(option);
    }
  },
  mounted () {
    this.load()
  }
}
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 0;
  flex-direction: column;

  .hd {
    text-align: center;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .bd {
    flex:1;
    height: 50px;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-indent: 20px;

    span {
      color: #096dd9;
      font-weight: bold;
      font-size: 35px;
      margin-left: 20px;
    }
  }

  .chart {
    flex: 1;
  }
}

ul {
  padding: 0 1em;
  list-style: none;
  li {
    font-size: 1em;
    padding: 3px 0;
    font-weight: bold;
    a {
      color: #fff;
    }
    .label {
      display: inline-block;
      margin-right: 10px;
      color:#25c6fc;
    }
  }
}
</style>
