<template>
  <div style="padding: 15px;">
    <!-- <h3 class="text-primary">电表性能报告查询</h3> -->
    <div class="query-bar">
      <el-select v-model="query.deviceOpenId" @change="deviceSelected" filterable placeholder="请选择设备" style="width: 260px;">
        <el-option v-for="item in deviceList" :key="item.openId" :label="item.name" :value="item.openId"></el-option>
      </el-select>

      <el-date-picker v-model="query.dateRange" type="daterange" value-format="timestamp" align="center" :clearable="false" unlink-panels start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="queryDatePickerOptions">
      </el-date-picker>
      <el-time-select v-model="query.fixTime" :picker-options="{ start: '00:00', step: '00:5', end: '23:55' }" value-format="timestamp" placeholder="选择时间">
      </el-time-select>
      <el-button type="primary" @click.prevent="load">
        <i class="fa fa-search"></i>
      </el-button>
    </div>
    <div>
      <chart :options="chartOptions" ref="barChart" auto-resize style="width: 100%; height: 500px;"></chart>
      <div class="text-center" style="padding: 15px 0;">
        <el-radio-group v-model="chartMetics" size="medium" @change="chartMeticsChange">
          <el-radio-button :label="1">温度</el-radio-button>
          <el-radio-button :label="2">电流</el-radio-button>
          <el-radio-button :label="3">电压</el-radio-button>
          <el-radio-button :label="4">用电量</el-radio-button>
          <el-radio-button :label="5">剩余(漏)电流</el-radio-button>
          <el-radio-button :label="6">有功功率</el-radio-button>
          <el-radio-button :label="7">功率因素</el-radio-button>
        </el-radio-group>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { deviceList, devicePerformanceFixTime } from '@/api/api'

export default {
  data () {
    this.chartSettings = {}
    return {
      query: {
        deviceOpenId: '',
        dateRange: [new Date().getTime() - 6 * 3600 * 1000 * 24, new Date().getTime() + 3600 * 1000 * 24],
        fixTime: '09:00'
      },
      queryDatePickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick (picker) {
            const today = moment({ hour: 0 })
            const tomorrow = moment({ hour: 0 }).add(1, 'days')
            const start = today.toDate()
            const end = tomorrow.toDate()
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一天',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近两天',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 2)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      deviceList: [],
      chartOptions: {},
      chartMetics: 1
    }
  },
  methods: {
    load () {
      let params = {
        deviceOpenId: this.query.deviceOpenId,
        startTime: this.query.dateRange[0],
        endTime: this.query.dateRange[1],
        fixTime: moment(this.query.fixTime, 'HH:mm').valueOf()
      }
      this.chartMetics = 1
      let barChart = this.$refs.barChart
      barChart.showLoading()
      devicePerformanceFixTime(params).then((data) => {
        if (data.result && data.result.length > 0) {
          this.chartOptions = {
            legend: {
              textStyle: {
                fontSize: 18
              }
            },
            color: ['#005eaa', '#2b821d', '#25C6FC', '#c23531', '#91c7ae', '#749f83', '#ca8622', '#bda29a', '#6e7074', '#546570', '#c4ccd3'],
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                label: {
                  show: true,
                  formatter: (param) => {
                    let date = new Date(param.value)
                    let str = this.$filter['date'](date, 'YYYY-MM-DD HH:mm')
                    return str
                  }
                }
              }
            },
            dataset: {
              source: data.result
            },
            xAxis: { type: 'time' },
            yAxis: { scale: true },
            series: this.getChartSeries(['temperatureA', 'temperatureB', 'temperatureC', 'temperatureN'])
          }
        } else {
          this.$message.warning('该时间段暂无数据！')
        }
        barChart.hideLoading()
      })
    },
    getChartSeries (list) {
      let labelMap = {
        'createTime': '时间',
        'temperatureA': '温度A',
        'temperatureB': '温度B',
        'temperatureC': '温度C',
        'temperatureN': '温度N',
        'currentA': '电流A',
        'currentB': '电流B',
        'currentC': '电流C',
        'voltageA': '电压A',
        'voltageB': '电压B',
        'voltageC': '电压C',
        'electricalDegree': '用电量',
        'leakageElectricity': '剩余(漏)电流',
        'activePower': '有功功率',
        'powerFactor': '功率因素'
      }
      let serisesArray = []
      for (let seriesName of list) {
        if (typeof seriesName === 'string') {
          serisesArray.push({
            name: labelMap[seriesName],
            type: 'line',
            encode: {
              x: 'createTime',
              y: seriesName
            },
            showSymbol: true,
            symbol: 'circle',
            symbolSize: 10,
            lineStyle: {
              width: 0
            }
          })
        }
      }
      return serisesArray
    },
    chartMeticsChange () {
      if (this.chartMetics === 1) {
        this.chartOptions.series = this.getChartSeries(['temperatureA', 'temperatureB', 'temperatureC', 'temperatureN'])
      } else if (this.chartMetics === 2) {
        this.chartOptions.series = this.getChartSeries(['currentA', 'currentB', 'currentC'])
      } else if (this.chartMetics === 3) {
        this.chartOptions.series = this.getChartSeries(['voltageA', 'voltageB', 'voltageC'])
      } else if (this.chartMetics === 4) {
        this.chartOptions.series = this.getChartSeries(['electricalDegree'])
      } else if (this.chartMetics === 5) {
        this.chartOptions.series = this.getChartSeries(['leakageElectricity'])
      } else if (this.chartMetics === 6) {
        this.chartOptions.series = this.getChartSeries(['activePower'])
      } else if (this.chartMetics === 7) {
        this.chartOptions.series = this.getChartSeries(['powerFactor'])
      }
    },
    deviceSelected () {
      this.$router.push({ query: { deviceOpenId: this.query.deviceOpenId } })
      localStorage.setItem('hudson.power.lastSelectDevice', this.query.deviceOpenId)
    }
  },
  created () {
    // init device list
    deviceList({ type: 0 }).then((data) => {
      this.deviceList = data.result
      if (this.$route.query.deviceOpenId) {
        this.query.deviceOpenId = this.$route.query.deviceOpenId
      }
      if (!this.query.deviceOpenId) {
        let lastSelecteDevice = localStorage.getItem('hudson.power.lastSelectDevice')
        if (lastSelecteDevice) {
          for (let item of this.deviceList) {
            if (lastSelecteDevice === item.openId) this.query.deviceOpenId = lastSelecteDevice
          }
        }
      }
      if (!this.query.deviceOpenId && this.deviceList[0]) this.query.deviceOpenId = this.deviceList[0].openId

      this.load()
    })
  }
}
</script>
<style lang="scss" scoped>
.el-radio-group /deep/ .el-radio-button__inner {
  font-size: 18px;
}
</style>
