<template>
  <div class="box">
    <div class="hd">区域用电</div>
    <div class="bd">
      <div id="labelsGraph" style="height: 100%; width: 100%"></div>
    </div>
    <dv-decoration-2 style="height:10px;" />
  </div>
</template>

<script>
// import ecStat from 'echarts-stat'
/* eslint-disable */
import * as echarts from 'echarts';

export default {
  name: 'MidChart2',
  data () {
    return {}
  },
  methods: {
    load() {
      var chartDom = document.getElementById('labelsGraph');
      var myChart = echarts.init(chartDom, 'dark');
      var option;

      var data = [
        [[200,800, 4000,'区域一',2015],[500,600, 8000,'区域二',2015],[900,500, 10000,'区域三',2015],[900,200, 9000,'区域四',2015]]
      ];

      option = {
          backgroundColor:'rgba(128, 128, 128, 0.1)',
          xAxis: {
            splitLine: { show: false },
            max: 1000,
            show: false
          },
          yAxis: {
            splitLine: { show: false },
            max: 1000,
            show: false
          },
          grid: {
              left: 40,
              right: 130
          },
          series: [{
              name: '1990',
              data: data[0],
              type: 'scatter',
              symbolSize: function (data) {
                return data[2] / 100;
              },
              emphasis: {
                focus: 'self',
                label: {
                  formatter: '本月用电:' + '\n' + '{@[2]} 度',
                  fontSize: 20,
                  lineHeight: 30,
                  height: 60,
                  width: 90,
                  align: 'center'
                }
              },
              labelLayout: function () {
                return {
                  x: myChart.getWidth() - 100,
                  moveOverlap: 'shiftY'
                };
              },
              labelLine: {
                show: true,
                length2: 5,
                lineStyle: {
                  color: 'white'
                }
              },
              label: {
                show: true,
                formatter: function (param) {
                    return param.data[3];
                },
                position: 'right',
                backgroundColor: '#6600ff',
                minMargin: 2,
                fontSize: 24,
                height: 30,
                verticalAlign: 'center',
                color: "#fff"
              }
          }]
      };

      option && myChart.setOption(option);
    },
  },
  mounted() {
    this.load()
  }
}
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 0;
  flex-direction: column;

  .hd {
    text-align: center;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .bd {
    flex:1;
    height: 50px;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-indent: 20px;
    background-image: url('../../assets/images/map.png');
    background-size: 100% 100%;

    span {
      color: #096dd9;
      font-weight: bold;
      font-size: 35px;
      margin-left: 20px;
    }
  }

  .chart {
    flex: 1;
  }
}

.el-table.table-dark {
  background-color: rgba(255, 255, 255, 0.1);
  /deep/ tr, /deep/ th {
    background-color: transparent;
  }
  /deep/ .el-table__row {
    // background-color: rgba(255, 255, 255, .2);
    color: #fff;
  }
  
  /deep/ .el-table__row.hover-row td {
    color: #fff;
    background-color: rgba(255, 255, 255, .2);
  }
  /deep/ .el-table__body tr:hover > td {
    color: #fff;
    background-color: rgba(255, 255, 255, .2);
  }
  // /deep/ .el-table__header th, .el-table__header tr {
    // background-color: rgba(255, 255, 255, .5);
    // color: #666;
  // }
}
</style>