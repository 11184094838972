<template>
  <div style="padding: 15px;">
    <!-- <h3 class="text-primary">电表性能报告查询</h3> -->
    <div class="query-bar clearfix">
      <el-select v-model="query.deviceOpenId" @change="deviceSelected" filterable placeholder="请选择设备" style="width: 260px;">
        <el-option v-for="item in deviceList" :key="item.openId" :label="item.name" :value="item.openId"></el-option>
      </el-select>

      <el-date-picker v-model="query.dateRange" type="daterange" value-format="timestamp" align="center" :clearable="false" unlink-panels 
      start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="queryDatePickerOptions">
      </el-date-picker>
      <el-button type="primary" @click.prevent="load">
        <i class="fa fa-search"></i>
      </el-button>
      <el-button plain type="primary" style="float: right;" @click="goToChart">
        <i class="fa fa-fw fa-line-chart"></i>
      </el-button>
      <div style="float: right;">
        <el-button type="primary" icon="fa fa-fw fa-file-excel-o" @click="exportExcel" :loading="exportLoading">
          导出Excel
          <span v-if="exportLoading">({{downloadTask.statusDesc}})</span>
        </el-button>
        <a v-if="downloadTask.status === 2" target="_blank" :href="downloadTask.statusRemark">
          <el-button plain>点击下载</el-button>
        </a>
        <span v-if="downloadTask.status === 100">{{downloadTask.statusRemark}}</span>
      </div>
    </div>
    <div class="clearfix">
      <el-table :data="devicePerformanceList" border>
        <el-table-column type="index" width="40" :fixed="true">
        </el-table-column>
        <el-table-column label="时间" width="140" :fixed="true">
          <template slot-scope="scope">{{scope.row.createTime | date('YYYY-MM-DD HH:mm')}}</template>
        </el-table-column>
        <el-table-column prop="blockChainHash" label="区块链校验">
          <template slot-scope="scope" v-if="scope.row.block.blockSeq">
            <i class="text-success fa fa-check-circle-o fa-lg"></i>
            <el-link target="_blank" :href="`https://explorer.hudsonpower.cn/block/payload/${scope.row.blockChainHash}`" type="primary"><i class="fa fa-chain"></i>{{scope.row.block.blockSeq}}</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="temperatureA" label="温度A">
        </el-table-column>
        <el-table-column prop="temperatureB" label="温度B">
        </el-table-column>
        <el-table-column prop="temperatureC" label="温度C">
        </el-table-column>
        <el-table-column prop="temperatureN" label="温度N">
        </el-table-column>
        <el-table-column prop="currentA" label="电流A">
        </el-table-column>
        <el-table-column prop="currentB" label="电流B">
        </el-table-column>
        <el-table-column prop="currentC" label="电流C">
        </el-table-column>
        <el-table-column prop="voltageA" label="电压A">
        </el-table-column>
        <el-table-column prop="voltageB" label="电压B">
        </el-table-column>
        <el-table-column prop="voltageC" label="电压C">
        </el-table-column>
        <el-table-column prop="electricalDegree" label="用电量">
        </el-table-column>
        <el-table-column prop="leakageElectricity" label="剩余(漏)电流">
        </el-table-column>
        <el-table-column prop="activePower" label="有功功率">
        </el-table-column>
        <el-table-column prop="powerFactor" label="功率因数">
        </el-table-column>
      </el-table>
      <el-pagination @current-change="load" @size-change="load" :current-page.sync="pageData.pageNo" :page-size.sync="pageData.pageSize" :page-sizes="[10, 20, 50, 100]" layout="total, prev, pager, next" :total="pageData.total" style="float: right;">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { deviceList, devicePerformancePage, devicePerformanceExportExcel, taskDetail, blockChainPayloadByHash } from '@/api/api'

export default {
  data () {
    this.chartSettings = {}
    return {
      query: {
        deviceOpenId: '',
        dateRange: [new Date().getTime() - 3600 * 1000 * 24, new Date().getTime() + 3600 * 1000 * 24]
      },
      queryDatePickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick (picker) {
            const today = moment({ hour: 0 })
            const tomorrow = moment({ hour: 0 }).add(1, 'days')
            const start = today.toDate()
            const end = tomorrow.toDate()
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一天',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近两天',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 2)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      pageData: {
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      deviceList: [],
      devicePerformanceList: [],
      downloadTask: {},
      exportLoading: false
    }
  },
  methods: {
    load () {
      let params = {
        deviceOpenId: this.query.deviceOpenId,
        startTime: this.query.dateRange[0],
        endTime: this.query.dateRange[1],
        pageNo: this.pageData.pageNo - 1,
        pageSize: this.pageData.pageSize
      }
      devicePerformancePage(params).then((data) => {
        if (data.result.content && data.result.content.length > 0) {
          this.devicePerformanceList = data.result.content

          for (let p of this.devicePerformanceList) {
            this.$set(p, 'block', {})
            if (p.blockChainHash) {
              blockChainPayloadByHash({hash: p.blockChainHash}).then((res) => {
                if (res.status === 200 && res.data.ok) p.block = res.data
              })
            }
          }
          // this.pageDate.pageNo = data.result.number
          this.pageData.total = data.result.totalElements
        } else {
          this.devicePerformanceList = []
          this.$message.warning('该时间段暂无数据！')
        }
      })

      this.downloadTask = {}
      this.exportLoading = false
    },
    deviceSelected () {
      this.$router.push({ query: { deviceOpenId: this.query.deviceOpenId } })
      localStorage.setItem('hudson.power.lastSelectDevice', this.query.deviceOpenId)
    },
    goToChart () {
      this.$router.push({ path: '/report/power/performance/chart', query: this.$route.query })
    },
    exportExcel () {
      let params = {
        deviceOpenId: this.query.deviceOpenId,
        startTime: this.query.dateRange[0],
        endTime: this.query.dateRange[1],
        pageNo: this.pageData.pageNo - 1,
        pageSize: this.pageData.pageSize
      }
      this.exportLoading = true
      this.downloadTask = {}
      devicePerformanceExportExcel(params).then(async (data) => {
        this.downloadTask = data.result

        function sleep (ms) {
          return new Promise(resolve => setTimeout(resolve, ms))
        }
        while (this.exportLoading && this.downloadTask.id) {
          await sleep(2000)
          taskDetail({ id: this.downloadTask.id }).then((data) => {
            if (data.result && data.result.status !== undefined) {
              this.downloadTask = data.result
              if (this.downloadTask.status === 2 || this.downloadTask.status === 9) {
                this.exportLoading = false
              }
            }
          })
        }
      })
    }
  },
  created () {
    // init device list
    deviceList({type: 0}).then((data) => {
      this.deviceList = data.result
      if (this.$route.query.deviceOpenId) {
        this.query.deviceOpenId = this.$route.query.deviceOpenId
      }
      if (!this.query.deviceOpenId) {
        let lastSelecteDevice = localStorage.getItem('hudson.power.lastSelectDevice')
        if (lastSelecteDevice) {
          for (let item of this.deviceList) {
            if (lastSelecteDevice === item.openId) this.query.deviceOpenId = lastSelecteDevice
          }
        }
      }
      if (!this.query.deviceOpenId && this.deviceList[0]) this.query.deviceOpenId = this.deviceList[0].openId

      this.load()
    })
  }
}
</script>
