<template>
  <div>
    <el-tree
      ref="tree"
      v-if="dataset.id"
      :props="props"
      :load="loadNode"
      lazy
      node-key="value"
      :default-expanded-keys="['中国']"
      @node-click="nodeClick">
    </el-tree>
    <p v-else>loading....</p>
  </div>
</template>
<script>
import { labelTree } from '@/api/api'

export default {
  name: 'AreaEditor',
  props: {
    selected: {
      type: Array,
      default: function () { return [] }
    },
    selectedModel: {
      type: Array,
      default: function () { return [] }
    }
  },
  data () {
    return {
      props: {
        children: 'children',
        label: 'value',
        isLeaf: 'leaf'
      },
      dataset: {}
    }
  },
  watch: {
    // selected (newVal, oldVal) {
    // console.log(newVal, oldVal)
    //   this.load()
    // }
  },
  methods: {
    load () {
      let params = {
        key: 'area',
        parentId: 0
      }
      labelTree(params).then((data) => {
        this.dataset = data.result
      })
    },
    loadNode (node, resolve) {
      if (node.level === 0) {
        resolve([this.dataset])
      }

      if (node.level === 1) {
        resolve(this.dataset.children)
      }

      if (node.level >= 2) {
        let params = {
          key: 'area',
          parentId: node.data.id
        }
        labelTree(params).then((data) => {
          if (node.level === 4) {
            for (let e of data.result) {
              e.leaf = true
            }
          }
          resolve(data.result)
        })
      }
    },
    nodeClick (data, node, element) {
      let values = []
      let models = []
      let currentNode = node
      while (currentNode) {
        if (currentNode.data && currentNode.data.value) {
          values.unshift(currentNode.data.value)
          models.unshift(currentNode.data)
        }
        currentNode = currentNode.parent
      }
      // console.log(values)
      this.$emit('update:selected', values)
      this.$emit('update:selectedModel', models)
    }
  },
  created () {
    this.load()
  }
}
</script>
<style lang="scss" scoped>
</style>
